import React, { useEffect, useState } from "react";
import Footer from "../components/Footer.js";
import Navbar from "../components/Navbar.js";
import AOS from "aos";
import "aos/dist/aos.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import io from "socket.io-client";
import config from "../config";
// img
import w1 from "../assets/images/w1.svg";
import w2 from "../assets/images/w2.svg";
import w3 from "../assets/images/w3.svg";
import w4 from "../assets/images/w4.svg";
import robo from "../assets/images/favicon.svg";
import t1 from "../assets/images/t1.gif";
import t2 from "../assets/images/t2.gif";
import t3 from "../assets/images/t3.gif";
import t4 from "../assets/images/t4.gif";
import t5 from "../assets/images/t5.gif";
import linearw from "../assets/images/line-arw.svg";
import support_img from "../assets/images/support_img.svg";
import rightarw from "../assets/images/rightarw.svg";
import quote from "../assets/images/quote.svg";
import { setUserToken } from "../utils/cookies";
import { useDispatch } from "react-redux";
import { getUserProfile } from "../redux/features/user/userslice";
import { getDefStore } from "../redux/features/defaultstore/defaultStoreSlice";
import { getUserStore } from "../redux/features/userstore/userStoreSlice";
import { getUserDraft } from "../redux/features/draft/mydraftslice";
import { getMyproduct } from "../redux/features/getmyProduct/myProductSlice";
import { toastAlert } from "../utils/toast";
import { useCookies } from "react-cookie";
import { getAllFaq, getSiteSetting } from "../action/user.action.js";
import { useTranslation, Trans } from "react-i18next";
import { useTheme } from "../components/ThemeContext";
import { getUserWallet } from "../redux/features/wallet/walletslice.js";

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Home(props) {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { theme } = useTheme();

  const [faq, setFaq] = useState([]);

  const [cookies, setCookie, removeCookie] = useCookies(["name"]);
  useEffect(() => {
    AOS.init();
  }, []);

  var settings = {
    dots: true,
    infinite: true,
    arrows: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  useEffect(() => {
    // console.log("Effec");
    const socket = io.connect(config.API_URL);
    socket.on("checkReDir", function (data) {
      // console.log(data, "DDDDDDDDDDDDDDD");
      if (data.token) {
        setCookie("loggedin", true, { path: "/dashboard" });
        setUserToken(data.token);
        dispatch(getUserProfile());
        dispatch(getDefStore());
        dispatch(getUserStore());
        dispatch(getUserDraft());
        dispatch(getMyproduct());
        dispatch(getUserWallet());

        setTimeout(() => {
          window.location.href = "/dashboard";
        }, 1000);

        toastAlert("success", "Logged In Successfully");
      }
    });
  }, []);

  const reDirectLinks = () => {
    window.location.href = "/login";
  };

  const fetchData = async () => {
    try {
      const { status, result } = await getAllFaq();
      console.log(status, result, "1111");
      if (status) {
        setFaq(result);
      }
    } catch (error) {}
  };

  const [settting, setSiteSettings] = useState({});

  const getSiteSettings = async () => {
    try {
      const { status, result } = await getSiteSetting();

      if (status) {
        setSiteSettings(result);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSiteSettings();
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      <ScrollToTopOnMount />
      <Navbar />
      <ul class="cta_list">
        <li>
          <div className="round">
            <div>
              <a target="_blank" href={settting?.externalLink}>
                <img src={robo} width="50%" class="img-fluid" alt="img" />
              </a>
            </div>
          </div>
        </li>
      </ul>
      <div className="page_header" name="features">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 mx-auto">
              <div className="box">
                <div data-aos="fade-up" data-aos-duration="1000">
                  <h1>
                    {
                      /* Build Al powered Drop shipping Store in just 5 minutes with{" "}
                    <span>ShoppyAI</span> */
                      <Trans
                        i18nKey="HOMEPAGE_TITLE"
                        components={{ span: <span /> }}
                        values={{ ShoppyAI: "ShoppyAI" }}
                      />
                    }
                  </h1>
                  <p className="px-lg-5 mx-lg-5">{t("TITLE_CONTENT")}</p>
                  <button
                    className="primary_btn d-inline-flex align-items-center gap-3"
                    onClick={reDirectLinks}
                  >
                    <span> {t("GET_STARTED")}</span>
                    <img
                      src={require("../assets/images/rightarw.png")}
                      class="img-fluid"
                      alt="arw"
                    />
                  </button>
                </div>
                <img
                  src={require("../assets/images/ban.png")}
                  data-aos="zoom-in"
                  data-aos-duration="1000"
                  class="img-fluid mt-5 pt-4"
                  alt="img"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="main_wrapper">
        <section className="why_sec" name="why">
          <div className="container">
            <div className="row">
              <div
                className="col-lg-6 mx-auto text-center mb-4"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <h2 className="h2tag">
                  {/* The Benefits of<span> ShoppyAI</span> */}
                  <Trans
                    i18nKey="HEADER1"
                    components={{ span: <span /> }}
                    values={{ ShoppyAI: "ShoppyAI" }}
                  />
                </h2>
                <p>{t("TITLE1")}</p>
              </div>
            </div>
            <div className="row">
              <div
                className="col-lg-6 col-xl-5 d-flex"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <div className="box sandal">
                  <div className="row">
                    <div className="col-lg-7">
                      <h5>{t("HEADER2")}</h5>
                      <p>{t("TITLE2")}</p>
                      <button onClick={reDirectLinks}>
                        {t("GET_STARTED")}
                      </button>
                    </div>
                    <div className="col-lg-5 text-end">
                      <img src={w1} class="img-fluid" alt="img" />
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-6 col-xl-7 d-flex"
                data-aos="fade-up"
                data-aos-delay="200"
                data-aos-duration="1000"
              >
                <div className="box rose">
                  <div className="row">
                    <div className="col-lg-7">
                      <h5>{t("HEADER3")}</h5>
                      <p>{t("TITLE3")}</p>
                      <button onClick={reDirectLinks}>
                        {t("GET_STARTED")}
                      </button>
                    </div>
                    <div className="col-lg-5 text-end">
                      <img src={w2} class="img-fluid" alt="img" />
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-6 col-xl-7 d-flex"
                data-aos="fade-up"
                data-aos-delay="400"
                data-aos-duration="1000"
              >
                <div className="box green mb-lg-0">
                  <div className="row">
                    <div className="col-lg-7">
                      <h5>{t("HEADER4")}</h5>
                      <p>{t("TITLE41")}</p>
                      <p>{t("TITLE42")}</p>
                      <button onClick={reDirectLinks}>
                        {t("GET_STARTED")}
                      </button>
                    </div>
                    <div className="col-lg-5 text-end">
                      <img src={w3} class="img-fluid" alt="img" />
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-6 col-xl-5 d-flex"
                data-aos="fade-up"
                data-aos-delay="600"
                data-aos-duration="1000"
              >
                <div className="box violet mb-0">
                  <div className="row">
                    <div className="col-lg-7">
                      <h5>{t("HEADER411")}</h5>
                      <p>{t("TITLE411")} </p>
                      <button onClick={reDirectLinks}>
                        {t("GET_STARTED")}
                      </button>
                    </div>
                    <div className="col-lg-5 text-end">
                      <img src={w4} class="img-fluid" alt="img" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          className="supporting"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-6 m-auto">
                <h3 className="h2tag">{t("HEADER5")}</h3>
                <p className="para">{t("TITLE5")}</p>
                <button onClick={reDirectLinks}>{t("GET_STARTED")}</button>
              </div>
              <div className="col-lg-6">
                <div
                  className="img"
                  data-aos="fade-up"
                  data-aos-delay="400"
                  data-aos-duration="1000"
                >
                  <img
                    src={support_img}
                    class="img-fluid mx-auto mt-auto"
                    alt="img"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          className="suppliers"
          name="suppliers"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <div className="row">
            <div className="col-lg-6 mx-auto text-center">
              <h2 className="h2tag">{t("HEADER6")}</h2>
              <p className="para">{t("TITLE6")}</p>
            </div>
            <div className="boxes">
              <div className="row">
                <div className="col-6 d-flex">
                  <div className="overflow-hidden d-flex align-items-center justify-content-end pe-5 me-5">
                    <div className="box left">
                      <div>
                        <img
                          src={require("../assets/images/s1.png")}
                          class="img-fluid"
                          alt="img"
                        />
                      </div>
                      <div>
                        <img
                          src={require("../assets/images/s2.png")}
                          class="img-fluid"
                          alt="img"
                        />
                      </div>
                      <div>
                        <img
                          src={require("../assets/images/s3.png")}
                          class="img-fluid"
                          alt="img"
                        />
                      </div>
                      <div>
                        <img
                          src={require("../assets/images/s4.png")}
                          class="img-fluid"
                          alt="img"
                        />
                      </div>
                      <div>
                        <img
                          src={require("../assets/images/s5.png")}
                          class="img-fluid"
                          alt="img"
                        />
                      </div>
                    </div>
                    <div className="box left">
                      <div>
                        <img
                          src={require("../assets/images/s1.png")}
                          class="img-fluid"
                          alt="img"
                        />
                      </div>
                      <div>
                        <img
                          src={require("../assets/images/s2.png")}
                          class="img-fluid"
                          alt="img"
                        />
                      </div>
                      <div>
                        <img
                          src={require("../assets/images/s3.png")}
                          class="img-fluid"
                          alt="img"
                        />
                      </div>
                      <div>
                        <img
                          src={require("../assets/images/s4.png")}
                          class="img-fluid"
                          alt="img"
                        />
                      </div>
                      <div>
                        <img
                          src={require("../assets/images/s5.png")}
                          class="img-fluid"
                          alt="img"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="overflow-hidden d-flex align-items-center justify-content-end ps-5 ms-5">
                    <div className="box left">
                      <div>
                        <img
                          src={require("../assets/images/b3.png")}
                          class="img-fluid"
                          alt="img"
                        />
                      </div>
                      <div>
                        <img
                          src={require("../assets/images/b4.png")}
                          class="img-fluid"
                          alt="img"
                        />
                      </div>
                      <div>
                        <img
                          src={require("../assets/images/s4.png")}
                          class="img-fluid"
                          alt="img"
                        />
                      </div>
                      <div>
                        <img
                          src={require("../assets/images/b3.png")}
                          class="img-fluid"
                          alt="img"
                        />
                      </div>
                      <div>
                        <img
                          src={require("../assets/images/s3.png")}
                          class="img-fluid"
                          alt="img"
                        />
                      </div>
                    </div>
                    <div className="box left">
                      <div>
                        <img
                          src={require("../assets/images/s4.png")}
                          class="img-fluid"
                          alt="img"
                        />
                      </div>
                      <div>
                        <img
                          src={require("../assets/images/b3.png")}
                          class="img-fluid"
                          alt="img"
                        />
                      </div>
                      <div>
                        <img
                          src={require("../assets/images/s2.png")}
                          class="img-fluid"
                          alt="img"
                        />
                      </div>
                      <div>
                        <img
                          src={require("../assets/images/s4.png")}
                          class="img-fluid"
                          alt="img"
                        />
                      </div>
                      <div>
                        <img
                          src={require("../assets/images/b4.png")}
                          class="img-fluid"
                          alt="img"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="round">
                <div>
                  <img src={robo} width="75%" class="img-fluid" alt="img" />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="product" name="integration">
          <div className="container">
            <div className="row" data-aos="fade-up" data-aos-duration="1000">
              <div className="col-lg-6 mx-auto text-center mb-3">
                <h2 className="h2tag">
                  <Trans
                    i18nKey="NEW_TITLE2"
                    components={{ span: <span /> }}
                    values={{ ShoppyAI: "ShoppyAI" }}
                  />

                  {/* Turn your products into profits at <span> ShoppyAI</span> */}
                </h2>
              </div>
            </div>
            <div className="row">
              <div
                className="col-lg-6 d-flex"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <div className="box">
                  <img src={t1} class="img-fluid icon" alt="img" />
                  <div>
                    <h5>{t("HEADER7")}</h5>
                    <p className="para">{t("TITLE7")}</p>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-6 d-flex"
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="200"
              >
                <div className="box">
                  <div>
                    <h5>{t("HEADER8")}</h5>
                    <p className="para">{t("TITLE8")}</p>
                  </div>
                  <img src={t2} class="img-fluid icon" alt="img" />
                </div>
              </div>
              <div
                className="col-lg-6 d-flex"
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="400"
              >
                <div className="box">
                  <img src={t3} class="img-fluid icon" alt="img" />
                  <div>
                    <h5>{t("HEADER9")}</h5>
                    <p className="para">{t("TITLE9")}</p>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-6 d-flex"
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="500"
              >
                <div className="box">
                  <div>
                    <h5>{t("HEADER10")}</h5>
                    <p className="para">{t("TITLE10")}</p>
                  </div>
                  <img src={t4} class="img-fluid icon" alt="img" />
                </div>
              </div>
              <div
                className="col-lg-6 d-flex"
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="600"
              >
                <div className="box">
                  <img src={t5} class="img-fluid icon" alt="img" />
                  <div>
                    <h5>{t("HEADER11")}</h5>
                    <p className="para">{t("TITLE11")}</p>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-6 d-flex"
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="700"
              >
                <div className="box justify-content-between  launch">
                  <div>
                    <h5>{t("HEADER12")}</h5>
                    <p className="para">{t("TITLE12")}</p>
                  </div>
                  <div className="w-50 text-end">
                    <button className="primary_btn d-inline-flex align-items-center gap-3">
                      <span onClick={reDirectLinks}> {t("GET_FREE")}</span>
                      <img
                        src={require("../assets/images/rightarw.png")}
                        class="img-fluid"
                        alt="arw"
                      />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="cta">
          <div className="container">
            <div className="row">
              <div
                className="col-xl-8 col-lg-10 mx-auto"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <div className="box">
                  <div className="row">
                    <div className="col-lg-8">
                      <h4>
                        {/* Jumpstart your dropshipping business with{" "}
                        <span>ShoppyAI</span> */}
                        <Trans
                          i18nKey="NEW_TITLE1"
                          components={{ span: <span /> }}
                          values={{ ShoppyAI: "ShoppyAI" }}
                        />
                      </h4>
                      <img src={linearw} class="img-fluid icon" alt="img" />
                    </div>
                    <div className="col-lg-4">
                      <button className="d-inline-flex align-items-center gap-3">
                        <span onClick={reDirectLinks}> {t("GET_STARTED")}</span>
                        <img
                          src={require("../assets/images/rightarw_ylw.png")}
                          class="img-fluid"
                          alt="arw"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="slide_sec">
          <div class="container">
            <div className="row">
              <div
                className="col-lg-3 mb-4 mb-lg-0"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <h2 className="h2tag mb-lg-5 mb-4">
                  {/* What do other entrepreneurs say about <span> ShoppyAI</span> */}
                  <Trans
                    i18nKey="REVIEW_HEADER"
                    components={{ span: <span /> }}
                    values={{ ShoppyAI: "ShoppyAI" }}
                  />
                </h2>
                <div>
                  {/* <a href="#" className="link" onClick={reDirectLinks}>
                    Check out the case studies
                  </a> */}
                  {/* <img src={rightarw} class="img-fluid ms-3" alt="img" /> */}
                </div>
              </div>
              <div
                className="col-lg-8"
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="400"
              >
                <div className="bgbox">
                  <div className="box">
                    <Slider {...settings}>
                      <div>
                        <div className="d-flex align-items-start gap-3">
                          <img src={quote} class="img-fluid" alt="img" />
                          <p className="para">
                            {/* I decided to step into the dropshipping business
                            only three months ago. I had no experience and
                            didn't know where to start. I searched for
                            dropshipping software and found ShoppyAI. Guess
                            what? After three months, I have already fulfilled
                            50+ orders! This tool helped me find trending
                            products in health care and organize my online store
                            most effectively. */}
                            {t("REVIEW1")}
                          </p>
                        </div>

                        <div className="usr_box">
                          <div className="user">
                            <img
                              src={require("../assets/images/p1.png")}
                              class="img-fluid"
                              alt="img"
                            />
                          </div>
                          <div>
                            <h5>{t("REVIEW_NAME1")}</h5>
                            {/* <p className="mb-0">CEO, Headphone Zone</p> */}
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="d-flex align-items-start gap-3">
                          <img src={quote} class="img-fluid" alt="img" />
                          <p className="para">{t("REVIEW2")}</p>
                        </div>

                        <div className="usr_box">
                          <div className="user">
                            <img
                              src={require("../assets/images/p2.png")}
                              class="img-fluid"
                              alt="img"
                            />
                          </div>
                          <div>
                            <h5>{t("REVIEW_NAME2")}</h5>
                            {/* <p className="mb-0">CEO, Headphone Zone</p> */}
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="d-flex align-items-start gap-3">
                          <img src={quote} class="img-fluid" alt="img" />
                          <p className="para">{t("REVIEW3")}</p>
                        </div>

                        <div className="usr_box">
                          <div className="user">
                            <img
                              src={require("../assets/images/p3.png")}
                              class="img-fluid"
                              alt="img"
                            />
                          </div>
                          <div>
                            <h5>{t("REVIEW_NAME3")}</h5>
                            {/* <p className="mb-0">CEO, Headphone Zone</p> */}
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="d-flex align-items-start gap-3">
                          <img src={quote} class="img-fluid" alt="img" />
                          <p className="para">{t("REVIEW4")}</p>
                        </div>

                        <div className="usr_box">
                          <div className="user">
                            <img
                              src={require("../assets/images/p4.png")}
                              class="img-fluid"
                              alt="img"
                            />
                          </div>
                          <div>
                            <h5>{t("REVIEW_NAME4")}</h5>
                            {/* <p className="mb-0">CEO, Headphone Zone</p> */}
                          </div>
                        </div>
                      </div>
                    </Slider>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="faq" name="faq">
          <div class="container">
            <div className="row">
              <div className="col-lg-7 col-xl-6 mx-auto">
                <div data-aos="fade-up" data-aos-duration="1000">
                  <h2 class="h2tag text-center">
                    {t("FREQUENTLY_ASKED_QUESTIONS")}
                  </h2>
                </div>
                <div class="accordion" id="accordionExample">
                  {faq &&
                    faq.map((ele, i) => (
                      <div
                        class="accordion-item"
                        data-aos="fade-up"
                        data-aos-duration="1000"
                      >
                        <h2 class="accordion-header" id="headingOne">
                          <button
                            class="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={`#collapseOne_${i}`}
                            aria-expanded="true"
                            aria-controls={`collapseOne_${i}`}
                          >
                            {ele.question}?
                          </button>
                        </h2>
                        <div
                          id={`collapseOne_${i}`}
                          class="accordion-collapse collapse"
                          aria-labelledby="headingOne"
                          data-bs-parent="#accordionExample"
                        >
                          <div class="accordion-body">
                            <p>{ele.answer}</p>
                          </div>
                        </div>
                      </div>
                    ))}

                  {/* <div
                    class="accordion-item"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                    data-aos-delay="200"
                  >
                    <h2 class="accordion-header" id="headingTwo">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                        aria-expanded="false"
                        aria-controls="collapseTwo"
                      >
                        How profitable is dropshipping?
                      </button>
                    </h2>
                    <div
                      id="collapseTwo"
                      class="accordion-collapse collapse"
                      aria-labelledby="headingTwo"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <p>
                          Pellentesque vitae volutpat lectus. Nullam faucibus
                          ultricies justo, non volutpat neque volutpat eu.
                          Maecenas ut malesuada lacus, et cursus urna. Morbi
                          venenatis varius lobortis. Donec id eros tellus.
                          Aliquam tincidunt nisl posuere elementum placerat.
                          Fusce id imperdiet felis.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    class="accordion-item"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                    data-aos-delay="400"
                  >
                    <h2 class="accordion-header" id="headingThree">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseThree"
                        aria-expanded="false"
                        aria-controls="collapseThree"
                      >
                        How do I start a dropshipping business?
                      </button>
                    </h2>
                    <div
                      id="collapseThree"
                      class="accordion-collapse collapse"
                      aria-labelledby="headingThree"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <p>
                          Pellentesque vitae volutpat lectus. Nullam faucibus
                          ultricies justo, non volutpat neque volutpat eu.
                          Maecenas ut malesuada lacus, et cursus urna. Morbi
                          venenatis varius lobortis. Donec id eros tellus.
                          Aliquam tincidunt nisl posuere elementum placerat.
                          Fusce id imperdiet felis.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    class="accordion-item"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                    data-aos-delay="600"
                  >
                    <h2 class="accordion-header" id="headingfour">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapsefour"
                        aria-expanded="false"
                        aria-controls="collapsefour"
                      >
                        What products are best for dropshipping?
                      </button>
                    </h2>
                    <div
                      id="collapsefour"
                      class="accordion-collapse collapse"
                      aria-labelledby="headingfour"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <p>
                          Pellentesque vitae volutpat lectus. Nullam faucibus
                          ultricies justo, non volutpat neque volutpat eu.
                          Maecenas ut malesuada lacus, et cursus urna. Morbi
                          venenatis varius lobortis. Donec id eros tellus.
                          Aliquam tincidunt nisl posuere elementum placerat.
                          Fusce id imperdiet felis.
                        </p>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </div>
  );
}
