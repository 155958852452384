export default {
    "HOMEPAGE_TITLE": "Construye una tienda de dropshipping impulsada por IA en solo 5 minutos con <span>{{ShoppyAI}}</span>",
    "TITLE_CONTENT": "Abre un negocio sin la molestia de gestionar inventarios, empaques o envíos.",
    "GET_STARTED": "Comenzar",
    "NEW_TITLE1": "Impulsa tu negocio de dropshipping con <span>{{ShoppyAI}}</span>",
    "HEADER1": "Los beneficios de <span>{{ShoppyAI}}</span>",
    "TITLE1": "Los beneficios del dropshipping con IA pueden ser más de lo que imaginas si aprovechas al máximo el poder de la IA en tu negocio de dropshipping. En general, la IA tiene el potencial de mejorar tu negocio de dropshipping al proporcionar los siguientes beneficios:",
    "HEADER2": "Ahorra en costos de dropshipping",
    "TITLE2": "Al mejorar la eficiencia de tareas repetitivas en la gestión de una tienda de dropshipping, puedes reducir la necesidad de mano de obra manual y potencialmente bajar costos. Además, crear anuncios exitosos será más fácil con la ayuda de generadores de anuncios de IA, evitando gastar más en anuncios y campañas equivocadas.",
    "HEADER3": "Ofrece experiencias de compra personalizadas",
    "TITLE3": "Algunas herramientas de IA pueden analizar datos de clientes como compras anteriores, historial de navegación y preferencias para hacer recomendaciones de productos personalizadas y ofrecer descuentos, mejorando las experiencias de compra. A medida que los clientes estén satisfechos con tus servicios personalizados, es más probable que tomen decisiones de compra adicionales.",
    "HEADER4": "Entrega contenido atractivo en minutos",
    "TITLE41": "Las herramientas de redacción de IA son las más utilizadas en negocios de comercio electrónico. Tienen el poder de crear contenido de alta calidad para tu sitio web, publicaciones en redes sociales, anuncios, blogs, correos electrónicos, etc., de manera más eficiente. Y algunas generadoras de imágenes de IA pueden crear diseños de imágenes y videos atractivos para tu marca.",
    "TITLE42": "¡Estas herramientas están capacitadas para generar respuestas similares a las humanas y pueden proporcionar tonos y redacción personalizados según tus preferencias, creando contenidos atractivos para diferentes necesidades en redes sociales!",
    "HEADER5": "Apoyando tu crecimiento en cada paso del camino",
    "TITLE5": "En ShoppyAI, estamos dedicados a fomentar tu crecimiento personal y profesional en cada etapa de tu viaje. Ya sea que estés dando tus primeros pasos en un nuevo rol, avanzando en tus habilidades, o aspirando a liderazgo, nuestro compromiso es estar contigo en cada paso del camino.",
    "HEADER6": "Nuestros proveedores apoyados a nivel mundial",
    "TITLE6": "En ShoppyAI, nos enorgullece nuestra fuerte y diversa red de proveedores de todo el mundo. Estas asociaciones son la columna vertebral de nuestras operaciones, lo que nos permite ofrecer productos y servicios de alta calidad a nuestros clientes.",
    "NEW_TITLE2": "Convierte tus productos en ganancias en <span>{{ShoppyAI}}</span>",
    "HEADER7": "Elige tu plantilla",
    "TITLE7": "Nuestras plantillas están diseñadas para facilitar la navegación en cualquier dispositivo y asegurar una gran experiencia de compra.",
    "HEADER8": "Agrega tus productos",
    "TITLE8": "Agrega o importa tus productos, incluyendo descripciones, especificaciones e imágenes.",
    "HEADER9": "Configura tu envío e impuestos",
    "TITLE9": "Muestra tasas impositivas y opciones de envío específicas de la ubicación en tu tienda.",
    "HEADER10": "Publica tu tienda",
    "TITLE10": "Ve online con un clic y observa actualizaciones reflejadas en tu sitio web al instante.",
    "HEADER11": "Integra con pasarelas de pago",
    "TITLE11": "Conecta tu tienda con pasarelas de pago populares o permite que los clientes paguen de forma offline.",
    "HEADER12": "¡Lanza tu propia marca de comercio electrónico!",
    "TITLE12": "¡Tu camino hacia la ganancia comienza aquí!",
    "GET_FREE": "Obtén prueba gratuita",
    "REVIEW_HEADER": "¿Qué dicen otros emprendedores sobre <span>{{ShoppyAI}}</span>?",
    "REVIEW_NAME1": "Landon Le",
    "REVIEW1": "Decidí entrar en el negocio de dropshipping hace solo tres meses. No tenía experiencia y no sabía por dónde empezar. Busqué software de dropshipping y encontré ShoppyAI. ¿Adivina qué? ¡Después de tres meses, ya he cumplido más de 50 pedidos! Esta herramienta me ayudó a encontrar productos de tendencia en el cuidado de la salud y organizar mi tienda online de manera efectiva.",
    "REVIEW_NAME2": "Graciela Tyler",
    "REVIEW2": "ShoppyAI me ayudó a reiniciar mi negocio. Perdí a mi proveedor nacional de joyería femenina y quería encontrar nuevas formas de organizar mi negocio. Tenía miedo de empezar a hacer dropshipping sin productos en mi almacén; nunca lo había hecho antes. Sin embargo, lo intenté y amplié la selección de encantadoras joyas de cerámica para mis clientes y aumenté mi ganancia.",
    "REVIEW_NAME3": "Edison Price",
    "REVIEW3": "ShoppyAI no es mi primera herramienta de dropshipping. He probado varias para buscar productos para vender, actividades de reetiquetado y creación de tiendas online. No esperaba encontrar todo en una sola herramienta a un precio accesible. Ahora, recomiendo ShoppyAI a mis colegas como el mejor producto que vale su precio.",
    "REVIEW_NAME4": "Abel Nelson",
    "REVIEW4": "¡En nuestra era digital, es agradable cuando algo puede hacer todo el trabajo por ti! ¡ShoppyAI es la mejor herramienta de automatización! Logro tener cinco tiendas online activas donde toda la gestión de pedidos está automatizada. Me enfoco en campañas de promoción y solo en recibir mis ganancias.",
    "FREQUENTLY_ASKED_QUESTIONS": "¿Preguntas Frecuentes?",
    "FEATURES": "Características",
    "BENEFITS": "Beneficios",
    "INTEGRATIONS": "Integraciones",
    "SUPPLIERS": "Proveedores",
    "FAQ": "FAQ",
    "DASHBOARD": "Panel de Control",
    "SIGN_UP": "Regístrate",
    "SIGN_IN": "Iniciar Sesión",
    "START_CHAT": "Iniciar un Chat",
    "FOOTER1": "En ShoppyAI, no estamos vendiendo,",
    "FOOTER2": "¡Estamos teniendo éxito!",
    "REGISTERED_ADDRESS": "Dirección de la oficina registrada",
    "ShoppyAI": "ShoppyAI",
    "ABOUT": "Acerca de",
    "INVESTORS": "Inversores",
    "PARTNERS": "Socios",
    "AFFILIATES": "Afiliados",
    "LEGAL": "Legal",
    "SERVICE_STATUS": "Estado del servicio",
    "SUPPORT": "Soporte",
    "MERCHANT_SUPPORT": "Soporte para Comerciantes",
    "HELP_CENTER": "Centro de Ayuda",
    "HIRE_PARTNER": "Contratar un Socio",
    "COMMUNITY": "Comunidad",
    "PRODUCTS": "Productos",
    "SHOP": "Tienda",
    "SHOP_PAY": "Shop Pay",
    "ShoppyAI_PLUS": "ShoppyAI Plus",
    "LINKPOP": "Linkpop",
    "DEVELOPERS": "Desarrolladores",
    "ShoppyAI_DEV": "ShoppyAI.dev",
    "API_DOCUMENT": "Documentación de API",
    "DEV_DEGREE": "Dev Degree",
    "TERMS_OF_SERVICE": "Términos del Servicio",
    "PRIVACY_POLICY": "Política de Privacidad",
    "SITEMAP": "Mapa del Sitio",
    "PRIVACY_CHOICES": "Opciones de Privacidad",
    "COPY_RIGHTS": "Derechos de autor © {{year}} <a href='/'>{{title}}</a> Todos los derechos reservados",



    "REGISTER": "Registrarse",
    "REGISTER_HEADER": "Regístrate con <span>{{ShoppyAI}}</span>",
    "REGLOGIN_CONTENT": "Inicia sesión ahora en ShoppyAI para gestionar mejor tu cuenta de dropshipping y aumentar tus ganancias.",
    "FIRST_NAME": "Nombre",
    "FIRST_NAME_PLACEHOLDER": "Ingresa tu nombre",
    "FIRST_NAME_REQUIRED": "Nombre requerido",
    "ALPHABETS_CHECK": "Solo se permiten letras",
    "LAST_NAME": "Apellido",
    "LAST_NAME_PLACEHOLDER": "Ingresa tu apellido",
    "LAST_NAME_REQUIRED": "Apellido requerido",
    "EMAIL": "Dirección de correo electrónico",
    "EMAIL_PLACEHOLDER": "Ingresa tu dirección de correo electrónico",
    "EMAIL_REQUIRED": "Correo electrónico requerido",
    "EMAIL_INVALID": "Dirección de correo electrónico inválida",
    "EMAIL_NOT_EXISTS": "El correo electrónico no existe",
    "EMAIL_NOT_FOUND": "No podemos encontrar un usuario con esta dirección de correo electrónico.",
    "EMAIL_EXISTS": "El correo electrónico ya existe",
    "EMAIL_NOTVERIFIED": "Correo electrónico no verificado",
    "MOBILE_NUMBER": "Número de móvil",
    "MOBILE_PLACEHOLDER": "Ingresa tu número de móvil",
    "MOBILE_NUMBER_REQUIRED": "Número de móvil requerido",
    "MOBILE_NUMBER_INVALID": "Número de móvil inválido",
    "MOBILE_NUMBER_EXISTS": "El número de móvil ya existe",
    "PASSWORD": "Contraseña",
    "PASSWORD_PLACEHOLDER": "Ingresa tu contraseña",
    "PASSWORD_REQUIRED": "Contraseña requerida",
    "PASSWORD_INCORRECT": "Contraseña incorrecta",
    "PASSWORD_MIN_MAX": "La contraseña debe contener al menos una mayúscula, al menos una minúscula, al menos un número, al menos un carácter especial y tener un mínimo de 8 y un máximo de 18 caracteres.",
    "PASSWORD_MIN": "La contraseña debe contener al menos 8 caracteres",
    "PASSWORD_MAX": "La contraseña debe contener menos de 18 caracteres",
    "PASSWORD_UPPER": "La contraseña debe contener 1 letra mayúscula",
    "PASSWORD_LOWER": "La contraseña debe contener 1 letra minúscula",
    "PASSWORD_SPECIAL": "La contraseña debe contener 1 carácter especial",
    "PASSWORD_NUMERIC": "La contraseña debe contener 1 carácter numérico",
    "YOUR_PASSWORD": "Tu contraseña es",
    "STRONG": "Fuerte",
    "MEDIUM": "Medio",
    "WEAK": "Débil",
    "CONFIRM_PASSWORD": "Confirmar contraseña",
    "CONFIRM_PASSWORD_PLACEHOLDER": "Ingresa nuevamente tu contraseña",
    "CONFIRM_PASSWORD_REQUIRED": "Confirmación de contraseña requerida",
    "CONFIRM_PASSWORD_MISMATCH": "La contraseña y la confirmación de la contraseña no coinciden",
    "INVALID_REFERRAL_CODE": "Código de referido inválido",
    "ALREADY_HAVE_ACCOUNT": "¿Ya tienes una cuenta?",
    "LOGIN_HERE": "Inicia sesión aquí",
    "REGISTER_HERE": "Regístrate aquí",
    "LOGIN": "Iniciar sesión",
    "LOGIN_HEADER": "Iniciar sesión con <span>{{ShoppyAI}}</span>",
    "REMEMBER_ME": "Recordarme",
    "FORGOT_PASSWORD": "Olvidé mi contraseña",
    "FORGOT_SUCCESS": "¡Éxito! Te hemos enviado por correo electrónico el enlace para restablecer tu contraseña.",
    "DONT_HAVE_ACCOUNT": "¿No tienes una cuenta?",
    "SUBMIT": "Enviar",
    "REGISTER_SUCCESS": "Registro exitoso",
    "LOGIN_SUCCESS": "Sesión iniciada con éxito",
    "ACCOUNT_LOCKED": "Tu cuenta ha sido bloqueada por el administrador",
    "SOMETHING_WRONG": "Algo salió mal",
    "ERROR_ON_SERVER": "Error en el servidor",
    "ERROR_OCCURED": "Ocurrió un error",
    "VERIFY_ACCOUNT": "Verifica tu cuenta",
    "VERIFY_BOX": "Tu cuenta ha sido registrada exitosamente y está pendiente de verificación por correo electrónico. Si no recibes el correo,",
    "CLICK_HERE": "Haz clic aquí",
    "VERIFY_CONTENT1": "Hemos enviado un correo electrónico con un enlace de confirmación a tu dirección de correo electrónico. Para completar el proceso de registro, haz clic en el enlace de confirmación.",
    "VERIFY_CONTENT2": "Si no recibes ningún correo, verifica tu carpeta de spam o espera unos minutos para recibir el correo. También, verifica que ingresaste una dirección de correo electrónico válida durante el registro.",
    "ALREADY_VERIFIED": "Tu correo electrónico ha sido activado. ¡Por favor inicia sesión en tu cuenta!",
    "ACCOUNT_VERIFIED": "Tu cuenta ya ha sido activada. ¡Por favor inicia sesión en tu cuenta!",
    "USER_NOT_FOUND": "Usuario no encontrado",
    "RESENT_SUCCESS": "Reenviado con éxito",
    "RESET_PASSWORD": "Restablecer contraseña",
    "CONFIRM": "Confirmar",
    "RESET_SUCCESS": "Contraseña restablecida con éxito",
    "RESET_LINK_EXPIRED": "Tu enlace para restablecer la contraseña ha expirado",
    "RESENT_MAIL": "Te hemos enviado nuevamente el enlace de activación. ¡Por favor verifica tu bandeja de entrada/carpeta de spam!",
    "REQUIRED": "Requerido",
    "MYSTORE": "Mi tienda / Agregar tienda",
    "MARKETPLACE": "Mercado",
    "VIP_MARKETPLACE": "Mercado VIP",
    "ORDERS": "Pedidos",
    "ORDER": "Pedido",
    "FINANCE": "Finanzas",
    "MY_INVENTORY": "Mi inventario",
    "PACKAGE_SOLUTIONS": "Soluciones de paquetes",
    "CUSTOMER_SUPPORT": "Soporte al cliente",
    "REFER": "Referir a un amigo",
    "BALANCE": "Saldo",
    "DEPOSIT": "Depositar",
    "WITHDRAW": "Retirar",
    "SUBSCRIPTION_PACKAGE": "Paquete de suscripción",
    "PACKAGE": "Paquete",
    "WELCOME": "Bienvenido",
    "NOTIFICATION": "Notificación",
    "CLEARALL": "Eliminar todo",
    "BANK_DETAILS": "Detalles bancarios",
    "CHANGE_PASSWORD": "Cambiar contraseña",
    "LOGOUT": "Cerrar sesión",
    "CHANGE_LANG": "Cambiar idioma",
    "AMOUNT": "Cantidad",
    "PROCEED_PAY": "Proceder al pago",
    "PROCEED_WITHDRAW": "Proceder a retirar",
    "DEPOSIT_PLACEHOLDER": "Ingresa tu monto de depósito",
    "WITHDRAW_PLACEHOLDER": "Ingresa tu monto de retiro",
    "AMOUNT_REQUIRED": "Por favor ingresa la cantidad en USD",
    "INVALID_AMOUNT": "El campo de cantidad solo debe permitir valores numéricos",
    "AMOUNT_ERROR": "La cantidad debe ser mayor o igual a {{AMOUNT}} USD",
    "WITHDRAW_AMOUNT_REQUIRED": "El monto de retiro es requerido",
    "INVALID_WITHDRAW_AMOUNT": "Solo se permiten números",
    "BALANCE_TOO_LOW": "Tu saldo es bajo",
    "WITHDRAW_SUCCESS": "Solicitud de retiro enviada con éxito",
    "INSUFFICIENT_AMOUNT": "Cantidad insuficiente",
    "CURRENT_PASSWORD": "Contraseña actual",
    "NEW_PASSWORD": "Nueva contraseña",
    "NEW_PASSWORD_REQUIRED": "Contraseña nueva requerida",
    "CONFIRM_NEW_PASSWORD": "Confirmar nueva contraseña",
    "NEW_CONFIRM_MISMATCH": "La nueva contraseña y la confirmación de la contraseña no coinciden",
    "PASSWORD_SAME": "La nueva contraseña no puede ser la misma que la antigua",
    "TRY_ANOTHER_PASSWORD": "Por favor, intenta con otra contraseña",
    "CHANGE_PASSWORD_SUCCESS": "Contraseña cambiada con éxito",
    "NAME_BANK": "Nombre en el banco",
    "NAME_REQUIRED": "Nombre requerido",
    "INVALID_NAME": "Nombre inválido",
    "NAME_BANK_PLACEHOLDER": "Ingresa el nombre del titular",
    "IBAN": "IBAN",
    "IBAN_PLACEHOLDER": "Ingresa IBAN",
    "IBAN_REQUIRED": "IBAN requerido",
    "INVALID_IBAN": "IBAN inválido",
    "SWIFT": "Código Swift",
    "SWIFT_PLACEHOLDER": "Ingresa código Swift",
    "SWIFT_REQUIRED": "Código Swift requerido",
    "INVALID_SWIFT": "Código Swift inválido",
    "BANK_NAME": "Nombre del banco",
    "BANK_NAME_REQUIRED": "Nombre del banco requerido",
    "INVALID_BANK_NAME": "Nombre del banco inválido",
    "BANK_PLACEHOLDER": "Ingresa el nombre del banco",
    "BANK_DETAIL_SUCCESS": "Detalles bancarios subidos con éxito",
    "BANK_PENDING": "Subido con éxito, esperando verificación",
    "BANK_REJECTED": "Tu solicitud ha sido rechazada por el administrador",
    "SUCCESS": "Éxito",
    "REJECTED": "Rechazado",
    "CREATE_REFERRAL": "Crear referido",
    "REFERRAL_SUCCESS": "Referencia añadida con éxito",
    "CREATE_SUPPORT_TICKET": "Crear ticket de soporte",
    "CATEGORY": "Categoría",
    "SELECT_CATEGORY": "Seleccionar categoría",
    "CATEGORY_REQUIRED": "Categoría requerida",
    "MESSAGE": "Mensaje",
    "MESSAGE_REQUIRED": "Mensaje requerido",
    "MESSAGE_ERROR": "Por favor, ingresa tu mensaje",
    "UPLOAD_FILE": "Adjunto (Añadir archivo o arrastrar archivos aquí)",
    "UPLOAD_SIZE_RESTRICT": "Nota: MÁX 1 MB (solo .jpeg, .png, .jpg, .pdf)",
    "INVALID_IMAGE": "Formato de imagen inválido",
    "SUPPORT_ERROR": "Ticket de soporte no enviado",
    "SUPPORT_SUCCESS": "Ticket de soporte enviado con éxito",
    "TICKET_DETAILS": "Detalles del ticket",
    "TICKET_ID": "ID del ticket",
    "SUB": "Sub",
    "SEND": "Enviar",
    "OPEN": "Abierto",
    "CLOSED": "Cerrado",
    "ALL": "Todos",
    "ISSUE": "Problema",
    "STATUS": "Estado",
    "ADMIN": "Administrador",
    "YOU": "Tú",
    "FILE": "Archivo",
    "NO_RECORD": "Sin registro",
    "CLOSE_TICKET": "Satisfecho, cerrar este ticket",
    "CLOSE_SUCCESS": "Ticket cerrado con éxito",
    "REPLY_SUBMIT": "Respuesta enviada con éxito",
    "MESSAGE_SENT_SUCCESS": "Tu mensaje ha sido enviado con éxito",
    "TICKET_ALREADY_CLOSED": "Ticket ya cerrado",
    "DAILY_PROFIT": "Ganancia diaria",
    "CURRENCY": "Moneda",
    "TOTAL_ORDERS": "Total de pedidos",
    "TOTAL_PROFIT": "Ganancia total",
    "MY_PRODUCTS": "Mis productos",
    "NEW_PRODUCTS": "Nuevos productos",
    "SALES_OVERVIEW": "Resumen de ventas",
    "DAYS": "Días",
    "WEEK": "Semana",
    "MONTH": "Mes",
    "OVERALL": "General",
    "OVERVIEW": "Resumen",
    "TOTAL_SELL_COST": "Costo total de venta de pedidos",
    "MAINTENANCE_FEE": "Cuota mensual de mantenimiento de tienda",
    "ADVERTISEMENT": "Publicidad",
    "SHIPPING": "Envío",
    "STARTUP": "Inicio",
    "TOTAL_FEE": "Cuota total",
    "WORLD_OVERVIEW": "Resumen de cobertura mundial de la tienda",
    "ORDER_LIST": "Lista de pedidos",
    "VIEW_ORDERS": "Ver más pedidos",
    "TOP_SELLING": "Productos más vendidos",
    "ADD_PRODUCTS": "Agregar productos",
    "PRODUCT_NOT_FOUND": "No encontramos productos",
    "PROFIT": "Ganancia",
    "PRODUCT_COST": "Costo del producto",
    "IMPORT_COST": "Costo de importación",
    "ONE_TIME": "Pago único",
    "DEPOSIT_SUCCESS": "Monto depositado con éxito",
    "PRODUCT_NAME": "Nombre del producto",
    "ORDERID": "ID del pedido",
    "STORE_NAME": "Nombre de la tienda",
    "DATE_TIME": "Fecha y hora",
    "TOTAL_SALE": "Venta total",
    "ALL_CATEGORY": "Todas las categorías",
    "PRODUCT_PRICE": "Precio del producto",
    "IMPORT_PRICE": "Precio de importación",
    "HIGH": "Alto",
    "LOW": "Bajo",
    "SORT_BY": "Ordenar por",
    "RECENTS": "Recientes",
    "OLDEST": "Más antiguos",
    "TYPE_SOME": "Escribe algo",
    "GALLERY": "Galería",
    "LIST": "Lista",
    "ADDED_CART": "Añadido al carrito",
    "IMPORT_CART": "Importar al carrito",
    "SUBSCRIBE": "Por favor, suscríbete a VIP",
    "ACCOUNT_HISTORY": "Historial de cuentas",
    "SEARCH": "Buscar",
    "ORDERS_NOT_FOUND": "No encontramos pedidos",
    "REASON": "Razón",
    "TYPE": "Tipo",
    "PAYMENT_ID": "ID de pago",
    "PENDING": "Pendiente",
    "COMPLETED": "Completado",
    "ADMIN_DEPOSIT": "Depósito del administrador",
    "USER_NOT_FINISHED": "Depósito del usuario no finalizado",
    "SUCCEEDED": "Sucedido",
    "USER": "Usuario",
    "USER_NAME": "Nombre de usuario",
    "USER_EMAIL": "Correo electrónico del usuario",
    "BEFORE_BALANCE": "Saldo anterior",
    "AFTER_BALANCE": "Saldo posterior",
    "BILLED_MONTH": "Facturado mensualmente",
    "SUBSCRIBED": "Suscrito",
    "BUY_NOW": "Comprar ahora",
    "BUSINESS_DAYS": "Días hábiles para envío",
    "AIR_HUB": "Centros aéreos",
    "FOR": "Para",
    "INCLUDES": "Incluye",
    "AUTHORIZED": "Centro de envío autorizado® (FASC) y socios de alianza.",
    "ARE_YOU": "¿Estás seguro de comprar?",
    "PACKAGE_WITH": "Paquete con",
    "CANCEL": "Cancelar",
    "INSUFFICIENT_USD": "Tu saldo en USD es bajo",
    "SUBSCRIBED_SUCCESS": "Suscripción exitosa",
    "NO_PACKAGE": "No se encontró paquete",
    "ADVERTISE": "Anunciar a",
    "CLIENT_WORLD": "clientes en todo el mundo",
    "AIR_EXPRESS_HUBS": "Centros de aire exprés",
    "AVERAGE_DAILY_PACKAGE": "Volumen promedio de paquetes diarios",
    "AIRPORTS": "Aeropuertos atendidos",
    "SELL_COST": "Costo de venta",
    "SELECT_STORE": "Seleccionar tienda",
    "VIEW_PRODUCTS": "Ver producto",
    "STORE_NAME": "Nombre de la tienda",
    "EDIT_SUCCESS": "Editado con éxito",
    "EDIT_SELL_PRICE": "Editar precio de venta",
    "ID": "ID",
    "CREATED": "Creado",
    "SELLING_PRICE": "Precio de venta",
    "SELLING_PRICE_PLACEHOLDER": "Ingresa el monto de venta",
    "WAITING": "esperando",
    "SELLING_PRICE_REQUIRED": "El costo de venta no puede estar vacío",
    "SELLILNG_PRICE_MAX": "El costo de venta debe ser mayor que el costo del producto",
    "ARE_YOU_DELETE": "¿Estás seguro de eliminar?",
    "DELETE_SUCCESS": "Producto eliminado con éxito",
    "DELETE_NOTE": "Nota: No reembolsaremos tu costo de importación por eliminar este producto",
    "PRODUCT_DETAILS": "Detalles del producto",
    "PRODUCT_ID": "ID del producto",
    "REVIEWS": "Reseñas",
    "SHIPS_FROM": "Enviado desde",
    "22BUSINESS_DAYS": "22 días hábiles a",
    "US": "Estados Unidos",
    "PRODUCT_INFO": "Información del producto",
    "SPECIFICATIONS": "Especificaciones",
    "POLICY": "Política",
    "ITEM_COST": "Costo del artículo",
    "NUMBER_ORDERS": "Número de pedidos",
    "ADDED_DRAFT": "Añadido a borrador",
    "IMPORT_PRODUCT": "Importar producto",
    "PRODUCT_NOT_FOUND": "Producto no encontrado",
    "ALREADY_DRAFT": "Oops, el artículo ya está en borrador",
    "QUANTITY": "Cantidad",
    "ACTUAL_PRICE": "Precio actual",
    "USER_PROFIT": "Ganancia del usuario",
    "RETURN": "Devolver",
    "SEARCH_PRODUCT": "Buscar producto o tienda",
    "SELECT_DATE": "Seleccionar fecha",
    "STORE": "Tienda",
    "SEARCH_STORE": "Buscar nombre de tienda",
    "SETTINGS": "Configuraciones",
    "ADD_STORE": "Agregar tienda",
    "EDIT": "Editar",
    "SEARCH_ANYTHING": "Buscar cualquier cosa",
    "STORE_LOCATION": "Ubicación de la tienda",
    "SEARCH_COUNTRY": "Buscar un país...",
    "STORE_LOGO": "Logo de la tienda",
    "STORE_LOGO_RESTRICT": "Formato aceptable: png, jpeg, jpg",
    "UPDATE_SUCCESS": "Actualización exitosa",
    "STORE_NAME_REQUIRED": "Nombre de la tienda requerido",
    "COUNTRY_REQUIRED": "País requerido",
    "SELECT_DEFAULT": "Por favor, selecciona la tienda predeterminada",
    "FILE_SIZE": "El tamaño del archivo excede 30 MB",
    "SELECT_COUNTRY": "Seleccionar país",
    "STORE_NAME_PLACEHOLDER": "Ingresa el nombre de la tienda",
    "ACCEPTABLE_SIZE": "Tamaño aceptable: 30MB formato: png, jpeg, jpg",
    "SELECT_STORE_TYPE": "Seleccionar tipo de tienda",
    "CREATE_STORE": "Crear tienda",
    "STORE_ALREADY_EXISTS": "La tienda ya existe",
    "STORE_SUCCESS": "Tienda creada con éxito",
    "NO_DATA": "Sin datos",
    "DRAFT_PRODUCT": "Producto en borrador",
    "BACK": "Atrás",
    "ALL_PRODUCT": "Todo el producto",
    "FAILED": "Fallido",
    "SUCCESSFUL": "Exitoso",
    "ACTIONS": "Acciones - Importación rápida",
    "IMPORT": "Importar",
    "FOURTH_TAB": "contenido de la cuarta pestaña",
    "ARE_YOU_REMOVE": "¿Estás seguro de eliminar?",
    "SELECT_YOUR_STORE": "Selecciona tu tienda",
    "SELLING_PRICE_ERROR": "Ningún producto tiene precio de venta",
    "SELECT_STORE_ERROR": "Por favor, selecciona tu tienda",
    "PRODUCT": "Producto",
    "AVERAGE_MARKPRICE": "Precio de mercado promedio",
    "ACTION": "Acción",
    "SELL_PRICE": "Precio de venta",
    "INSUFFICIENT_FUNDS": "Fondos insuficientes",
    "REMOVE_SUCCESS": "Eliminado con éxito",
    "STORE_REQUIRED": "Tienda requerida",
    "PROFIT_PER_ORDER": "Ganancia por pedido",
    "IMPORT_STORE": "Importar tienda",
    "PRODUCT_ALREADY_SELECTED": "Producto ya en la tienda seleccionada",
    "IMPORT_SUCCESS": "Producto importado con éxito",
    "ALREADY_DEPOSITED": "Ya depositado",
    "CONFIRM_MAIL_SUCCESS": "Tu correo ha sido verificado, ahora puedes iniciar sesión",
    "IS_ACTIVE": "Tu correo ya está verificado",
    "UNAUTHORIZED": "No autorizado",
    "SHIPPING_TIME": "Tiempo de envío",
    "BUSSINESS_DAYS": "Días hábiles",
    "PRODUCT_SUCCESS": "{{SUCCESS}} Producto importado con éxito {{FAILER}} Producto fallido",



    "WALLET": "Billetera",
    "BANK_DETAILS": "Detalles del Banco",
    "DEPOSIT_TERMS": "Términos de Depósito",
    "MINIMUM_DEPOSIT_AMOUNT": "Cantidad mínima de depósito: ",
    "INVALID_IMAGE": "Imagen no válida",
    "MAINTENANCE_ERROR": "Mantenimiento en proceso, ¡intente más tarde!",
    "DEPOSIT_DESCRIPTION": "Los depósitos menores que la cantidad mínima no serán aceptados y no se permiten reembolsos",
    "DEPOSIT_NOTES1": "Envíe solo {{CURRENCY_SYMBOL}} a esta dirección de billetera.",
    "DEPOSIT_NOTES2": "Enviar monedas o tokens diferentes a {{CURRENCY_SYMBOL}} a esta billetera puede resultar en la pérdida total de su depósito",
    "DEPOSIT_NOTES3": "Por favor, suba una captura de pantalla o un hash de su transacción para que podamos aprobarla",
    "DEPOSIT_CARD": "Depositar con tarjeta",
    "DEPOSIT_ADDRESS": "Dirección de Depósito",
    "MEMO_ADDRESS": "Dirección de Memo",
    "DEPOSIT_IMAGE_RESTRICTION": "Tamaño máximo de archivo para subir 2MB (solo Jpg, Jpeg, Png, Pdf).",
    "DEPOSIT_AMOUNT_ERROR": "Por favor, ingrese un monto de depósito mayor o igual a {{AMOUNT}}",
    "DEPOSIT_SUCCESS": "Solicitud de depósito enviada con éxito",
    "ASSET_ERROR": "El campo de activos del usuario es obligatorio",
    "COPIED": "¡Copiado!",
    "DOCUMENT_UPLOAD": "Subir Documento (Opcional)",
    "UPLOAD": "Subir",
    "WITHDRAW_TERMS": "Términos de Retiro",
    "MINIMUM_WITHDRAW_AMOUNT": "La cantidad mínima de retiro es: ",
    "TERMS1": "Retiro máximo:",
    "TERMS2": "Tarifa de retiro:",
    "RECEIVER_ADDRESS": "Dirección del receptor",
    "RECEIVER_ADDRESS_PLACEHOLDER": "Por favor, ingrese la dirección de retiro aquí",
    "RECEIVER_ADDRESS_REQUIRED": "El campo de dirección del receptor es obligatorio",
    "WITHDRAW_AMOUNT": "Monto de Retiro",
    "WITHDRAW_AMOUNT_ERROR": "Por favor, ingrese un monto de retiro mayor o igual a {{AMOUNT}}",
    "MINIMUM_WITHDRAW_ERROR": "La cantidad mínima de retiro debe ser mayor o igual a {{AMOUNT}}",
    "WITHDRAW_MAXAMOUNT_ERROR": "La cantidad de retiro debe ser menor o igual a {{AMOUNT}}",
    "WITHDRAW_SUCCESS": "Solicitud de retiro enviada con éxito",
    "INSUFFICIENT_WITHDRAW": "Monto de retiro demasiado bajo",
    "WITHDRAW_OTP": "Un OTP ha sido enviado exitosamente a su dirección de correo electrónico. Por favor, revise su bandeja de entrada y use el código proporcionado para continuar",
    "HOLDER_NAME": "Nombre del Titular",
    "BANK_CODE": "Código del Banco",
    "BANKCODE_REQUIRED": "Código del Banco requerido",
    "INVALID_CODE": "Código no válido",
    "ACCOUNTNO": "Número de Cuenta",
    "ACCOUNTNO_REQUIRED": "Número de Cuenta requerido",
    "INVALID_ACCOUNTNO": "Número no válido",
    "COUNTRY": "País",
    "TOTAL_BALANCE": "Saldo Total",
    "BALANCE": "Saldo",
    "EXCHANGE": "Intercambio",
    "HIDE_ZERO_BALANCES": "Ocultar Saldos Cero",
    "FROM_CURRENCY": "Desde la Moneda",
    "TO_CURRENCY": "Hasta la Moneda",
    "TO_CURRENCY_REQUIRED": "El campo de moneda destino es obligatorio",
    "SELECT_CURRENCY": "Seleccionar Moneda",
    "INVALID_USER": "Usuario no válido",
    "INSUFFICIENT_BALANCE": "Saldo insuficiente",
    "TRANSFER_SUCCESS": "Transferido exitosamente",
    "AMOUNT_CAN'T_ZERO": "El monto debe ser mayor que cero",
    "AVAILABLE_BALANCE": "Saldo Disponible",
    "MAX": "Máx",
    "TOO_LARGE": "Demasiado grande",
    "ASSET": "Activo",
    "AMOUNT_PLACEHOLDER": "Ingrese Monto",
    "OTP": "OTP",
    "OTP_PLACEHOLDER": "Ingrese OTP",
    "RESENT_OTP": "Reenviar Código de Verificación",
    "OTP_VALIDUPTO": "OTP válido hasta",
    "OTP_REQUIRED": "El campo OTP es obligatorio",
    "OTPSTATUS_REQUIRED": "El estado del OTP es obligatorio",
    "INVALID_OTPSTATUS": "Estado de OTP no válido",
    "OTP_SENT_SUCCESS": "OTP enviado a su dirección de correo electrónico, por favor ingrese un OTP",
    "EXPIRED_OTP": "OTP expirado",
    "INVALID_OTP": "OTP no válido",
    "SEC": "Seg",
    "STORE_MARKETPLACE": "Mercado de Tienda",
    "STORE_PRICE": "Precio de Tienda",
    "INVALID_CURRENCY": "Moneda no válida",
    "VALID_AMOUNT": "Por favor, ingrese un monto válido",
    "INVALID_ASSET": "Activo no válido",
    "SITE_URL": "URL del Sitio",
    "PAYMENTYPE": "Tipo de Pago",
    "CURRENCYSYMBOL": "Símbolo de Moneda",
    "COIN_DEPOSIT": "Depósito de Monedas",
    "COIN_WITHDRAW": "Retiro de Monedas",
    "COIN_TRANSFER": "Transferencia de Monedas",
    "FIAT_DEPOSIT": "Depósito Fiat",
    "FIAT_WITHDRAW": "Retiro Fiat",
    "NEW_USER": "Nuevo Usuario",
    "BUYING_STORE": "Comprar Tienda",
    "BUYING_PRODUCT": "Comprar Productos",
    "USER_DEPOSIT": "Depósito del Usuario",
    "USER_WITHDRAW": "Retiro del Usuario Pendiente",
    "USER_WITHDRAW_VERIFY": "Retiro del Usuario Verificado",
    "USER_WITHDRAW_REJECT": "Retiro del Usuario Rechazado",
    "ADMIN_WITHDRAW": "Retiro del Administrador",
    "ADMIN_DEP": "Depósito del Administrador",
    "PROFITS": "Beneficios",
    "PACKAGE_SUBSCRIBE": "Suscripción de Paquete",
    "STORE_RENEWAL": "Renovación de Tienda",
    "RETURN_PRODUCT": "Devolver Producto",
    "COIN_TRANSFERS": "Transferencias de Monedas",
    "ROWS_PER_PAGE": "Filas por Página",


    "ABOUT_US": "Acerca de Nosotros",
    "ABOUT_LIST1": "ShoppyAI fue fundado",
    "ABOUT_LIST2": "Usuarios activos mensuales",
    "ABOUT_LIST3": "Países de los que son los usuarios",
    "ABOUT_LIST4": "Empleados",
    "ABOUNT_HEAD1": "Tu plataforma todo en uno para investigación de productos y cumplimiento",
    "ABOUT_PARA1": "Una buena investigación de productos es clave para el éxito, pero la realidad de abastecimiento, trato con agentes, envíos internacionales y aduanas es más compleja de lo que la mayoría espera. La visión de ShoppyAI es eliminar esta complejidad. Lo que ves es lo que puedes obtener aquí mismo en tu panel con solo unos pocos clics. Sin servicios de automatización externos, sin tratar con proveedores.",
    "ABOUNT_HEAD2": "Experiencia de saber en lugar de adivinar",
    "ABOUT_PARA2": "El equipo de investigación de ShoppyAI trabaja con vendedores de tiendas independientes, Amazon y eBay de todos los tamaños que ponen a prueba supuestos y ayudan a los analistas de ShoppyAI a validar sus datos. Gracias a una red de socios, la metodología de predicción de ShoppyAI no es solo teórica, sino que está en gran parte probada en la práctica. Es una investigación precisa, no números inventados como los de casi todos los llamados 'rastreadotes de ventas'.",
    "ABOUNT_HEAD3": "Transparencia",
    "ABOUT_PARA3": "Sabemos lo frustrante que puede ser sentirse abrumado con toneladas de sitios web anónimos y números ficticios. Nosotros lo solucionamos. ShoppyAI es una empresa en el corazón de Londres cuyos fundadores respaldan su metodología de investigación y servicios con sus nombres.",
    "ABOUNT_HEAD4": "Profesionalismo",
    "ABOUT_PARA4": "Entendemos tus necesidades básicas para poder operar como un negocio: facturas adecuadas, certificaciones de productos necesarias, asesoramiento competente. El equipo de soporte está compuesto por hablantes nativos de inglés que pueden manejar incluso las preguntas más difíciles y la burocracia.",
    "ABOUNT_HEAD5": "Espíritu Emprendedor",
    "ABOUT_PARA5": "Queremos que tengas éxito, especialmente si eres principiante. Por eso hicimos que el panel de ShoppyAI sea lo más fácil posible de usar, muy ágil, con muchas características para escalabilidad y crecimiento que deseamos haber tenido al construir nuestras primeras tiendas.",
    "ABOUNT_HEAD6": "Optimización",
    "ABOUT_PARA6": "El panel de ShoppyAI es solo el comienzo. Mejoramos, agregamos características, pedimos retroalimentación e implementamos esa retroalimentación. Creemos que cuando la calidad mejora, el crecimiento viene de forma natural. Por favor, danos la mayor cantidad de retroalimentación posible. ¡Cada bit cuenta!",
    "ABOUNT_HEAD7": "E-Commerce para Todos",
    "ABOUT_PARA7": "Queremos menos grandes jugadores, como Amazon y Costco, y más emprendedores. ShoppyAI empodera a los vendedores independientes, ayudándoles a formar un mercado diverso para todos. Aprendamos unos de otros, crezcamos juntos y ayudemos a otros a tener éxito.",
    "ABOUNT_HEAD8": "Amor",
    "ABOUT_PARA8": "Los miembros del equipo de ShoppyAI crean características que aman. Nadie implementa nada que no usaría por sí mismo. Reunimos a personas que tienen una profunda pasión por el e-commerce. Esta pasión se comparte con cada suscriptor a través del panel.",
    "AFFILIATE_PROGRAM": "Programa de Afiliados",
    "AFFILIATE_QUES": "¿Quién puede promocionar ShoppyAI?",
    "AFFILIATE_HEAD1": "Creadores de Contenido",
    "AFFILIATE_PARA1": "Utiliza el contenido listo para usar de ShoppyAI para promoción si eres un creador de contenido en Facebook, Instagram o TikTok.",
    "AFFILIATE_HEAD2": "Influencers de Redes Sociales",
    "AFFILIATE_PARA2": "Gana promoviendo ShoppyAI en redes sociales y expresando tu afecto por él como influencer.",
    "AFFILIATE_HEAD3": "YouTubers",
    "AFFILIATE_PARA3": "Ofrece información sobre ShoppyAI a través de un video de YouTube e incluye tu enlace de afiliado en la descripción.",
    "AFFILIATE_HEAD4": "Educadores",
    "AFFILIATE_PARA4": "Transforma tu contenido en una fuente de ingresos educando a otros sobre cómo utilizar efectivamente ShoppyAI.",
    "AFFILIATE_HEAD5": "Emprendedores",
    "AFFILIATE_PARA5": "Mejora el potencial de monetización de tu contenido guiando a otros en el uso efectivo de ShoppyAI.",
    "AFFILIATE_HEAD6": "Blogueros y Escritores",
    "AFFILIATE_PARA6": "Aumenta la rentabilidad de tus publicaciones de blog incorporando anuncios de ShoppyAI y tu enlace de afiliado.",
    "UNLOCK_POTENTIAL": "Desbloquea tu potencial",
    "COMMUNITY_PARA1": "Únete a un grupo de entusiastas del e-commerce afines. Comparte experiencias, haz preguntas y recibe consejos de personas que entienden tu trayectoria.",
    "COMMUNITY_HEAD2": "Selecciones de Productos Gratuitos",
    "COMMUNITY_PARA2": "Obtén acceso exclusivo a productos seleccionados a mano. Estas selecciones gratuitas te ayudarán a descubrir nuevos artículos que tienen el potencial de convertirse en los próximos superventas en tu tienda.",
    "COMMUNITY_HEAD3": "Perspectivas Valiosas",
    "COMMUNITY_PARA3": "Aprende de la sabiduría colectiva de la comunidad. Obtén información sobre lo que funciona en el e-commerce, ayudándote a tomar decisiones comerciales más inteligentes.",
    "COMMUNITY_HEAD4": "Oportunidades de Networking",
    "COMMUNITY_PARA4": "Conéctate con posibles socios, mentores o incluso inversores. El networking puede abrir nuevas avenidas para el crecimiento de tu negocio.",
    "COMMUNITY_HEAD5": "Noticias",
    "COMMUNITY_PARA5": "Mantente al tanto de las tendencias del e-commerce. Ser parte de esta comunidad significa que escucharás sobre las últimas noticias y estrategias primero.",
    "COMMUNITY_HEAD6": "Motivación",
    "COMMUNITY_PARA6": "Unirse a una comunidad de apoyo puede proporcionar el estímulo necesario para perseverar ante los desafíos de dirigir un negocio de e-commerce.",
    "COMMUNITY_HEAD7": "No hagas dropshipping como si fuera 2017",
    "COMMUNITY_PARA7": "Imagina que alguien te pregunta quién es tu proveedor, y luego tienes que decirles que es un agente privado, o AliExpress, o una herramienta de automatización que simplemente compra de AliExpress por ti. Es 2024. Tus clientes, tus ingresos y tus ganancias te lo agradecerán.",
    "TESTIMONIALS": "TESTIMONIOS",
    "WHAT_OTHERS": "Lo que otros emprendedores dicen sobre ShoppyAI",
    "SAYS_HEAD1": "Timo Herzog",
    "SAYS_PARA1": "«Después de 2 años en e-commerce y poco éxito, me di cuenta de que los rastreadores de tiendas precisos o herramientas de espionaje simplemente no existen. El éxito en el dropshipping requiere una investigación adecuada y un proveedor confiable para hacer el trabajo. ShoppyAI fue el cambio que necesitaba. Investigación real, transparente, a nivel universitario, combinada con logística y automatización propias. Me hizo pasar de ser un hustler a un emprendedor en menos de 3 meses.»",
    "SAYS_HEAD2": "Mario Giordano",
    "SAYS_PARA2": "«ShoppyAI es muy amigable para principiantes. Para los nuevos en dropshipping, esta es, con mucho, la mejor solución todo en uno. Los productos se envían en un paquete neutral, por lo que el cliente final no ve que es dropshipping. Para profesionales como yo, aún falta una opción de branding. Según el soporte, están trabajando en ello. Sin embargo, sigue siendo lo mejor para la investigación y el cumplimiento de productos que no requieren branding.»",
    "SAYS_HEAD3": "Lisa-Marie Meskendahl",
    "SAYS_PARA3": "«Excelente para manejar múltiples tiendas simultáneamente y probar productos rápidamente. No hay nada comparable en el mercado. Para dropshipping, sí lo hay, pero para mantenerse ágil y no sobreinvertir, no lo hay.»",
    "SAYS_HEAD4": "Farid Saadlou",
    "SAYS_PARA4": "«Envío confiable a nivel mundial, automatización completa, investigación transparente. La herramienta de tendencias de productos es especialmente genial. Siempre estoy listo para reaccionar a las demandas de mañana, y el análisis de estacionalidad me ayuda a saber cuándo vender qué.»",
    "SAYS_HEAD5": "Agnesa Veliu",
    "SAYS_PARA5": "«Cuando entré en el e-commerce hace muchos años, la complejidad de las aduanas, importaciones y contabilidad fiscal rápidamente me hizo perder el interés. Siempre he sido buena en marketing, pero mala en operaciones, especialmente en cuestiones legales. ShoppyAI solucionó estos problemas para mí, ¡y ahora estoy dirigiendo y haciendo crecer mi tienda!»",
    "SAYS_HEAD6": "Dominic Galagher",
    "SAYS_PARA6": "«¡ShoppyAI tiene, sin duda, el mejor soporte! Se preocupan, hacen preguntas, te asesoran, quieren que tengas éxito. Siempre me asombra la calidad del servicio.»",
    "SAYS_HEAD7": "Marc Malen",
    "SAYS_PARA7": "«Lo que comenzó como un ingreso adicional se convirtió en una empresa con ahora ocho empleados. ShoppyAI estuvo conmigo en todo el camino y sigue siendo mi herramienta de investigación y cumplimiento de productos. Experimento con otros proveedores, pero requiere tanto micromanagement. Con ShoppyAI sé que todo saldrá bien.»",
    "SAYS_HEAD8": "Dima Voronov",
    "SAYS_PARA8": "«Conecté mis tiendas. Simplemente funciona. La mayoría de las herramientas todo en uno son solo automatizadores que te conectan con agentes chinos dudosos o compran de AliExpress por ti. Me encanta que ShoppyAI sea un proveedor real. Facturas de IVA, certificados CE y asesoramiento de hablantes nativos de inglés con evidente experiencia en e-commerce. No más mendigar a agentes o vendedores de AliExpress que no saben qué es una factura.»"



}