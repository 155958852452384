export default {
    "HOMEPAGE_TITLE": "قم بإنشاء متجر دروبشيبينغ مدعوم بالذكاء الاصطناعي في غضون 5 دقائق فقط مع <span>{{ShoppyAI}}</span>",
    "TITLE_CONTENT": "افتح عملًا تجاريًا دون عناء إدارة المخزون أو التعبئة أو الشحن.",
    "GET_STARTED": "ابدأ الآن",
    "NEW_TITLE1": "ابدأ عملك في دروبشيبينغ مع <span>{{ShoppyAI}}</span>",
    "HEADER1": "فوائد <span>{{ShoppyAI}}</span>",
    "TITLE1": "يمكن أن تكون فوائد دروبشيبينغ المدعوم بالذكاء الاصطناعي أكثر مما تتخيل إذا استطعت استغلال قوة الذكاء الاصطناعي في عملك. بشكل عام، يمكن أن يحسن الذكاء الاصطناعي عملك في دروبشيبينغ من خلال تقديم الفوائد التالية:",
    "HEADER2": "توفير تكاليف دروبشيبينغ",
    "TITLE2": "من خلال تحسين كفاءة المهام المتكررة في إدارة متجر دروبشيبينغ، يمكنك تقليل الحاجة إلى العمالة اليدوية وتقليل التكاليف. بالإضافة إلى ذلك، سيكون من الأسهل إنشاء إعلانات ناجحة بمساعدة مولدات إعلانات الذكاء الاصطناعي، لذا يمكنك تجنب إنفاق المزيد على الإعلانات والحملات غير الناجحة.",
    "HEADER3": "تقديم تجارب تسوق شخصية",
    "TITLE3": "يمكن لبعض أدوات الذكاء الاصطناعي تحليل بيانات العملاء مثل المشتريات السابقة، وسجل التصفح، وتفضيلات العملاء لتقديم توصيات منتجات شخصية وعروض خصم، مما يحسن من تجربة تسوق العملاء. مع رضا العملاء عن خدماتك الشخصية، سيكونون أكثر احتمالًا لاتخاذ قرارات شراء إضافية.",
    "HEADER4": "تقديم محتوى جذاب في دقائق",
    "TITLE41": "تُعد أدوات كتابة النصوص المدعومة بالذكاء الاصطناعي الأكثر استخدامًا في الأعمال التجارية الإلكترونية. لديها القدرة على إنشاء محتوى عالي الجودة لموقعك، ومنشورات وسائل التواصل الاجتماعي، والإعلانات، والمدونات، والبريد الإلكتروني، إلخ. بشكل أكثر كفاءة. وبعض مولدات الصور المدعومة بالذكاء الاصطناعي يمكن أن تنتج تصميمات جذابة من الصور ومقاطع الفيديو لعلامتك التجارية.",
    "TITLE42": "تلك الأدوات مدربة على إنشاء استجابات شبيهة بالبشر ويمكنها تقديم نغمات وكتابات شخصية وفقًا لتفضيلاتك، وإبداعات جذابة لاحتياجات وسائل التواصل الاجتماعي المختلفة!",
    "HEADER5": "ندعم نموك في كل خطوة على الطريق",
    "TITLE5": "في ShoppyAI، نلتزم بتعزيز نموك الشخصي والمهني في كل مرحلة من رحلتك. سواء كنت تتخذ خطواتك الأولى في دور جديد، أو تتقدم في مهاراتك، أو تهدف إلى القيادة، فإن التزامنا هو أن نكون معك في كل خطوة على الطريق.",
    "HEADER6": "موردونا المدعومون عالميًا",
    "TITLE6": "في ShoppyAI، نفخر بشبكتنا القوية والمتنوعة من الموردين من جميع أنحاء العالم. هذه الشراكات هي العمود الفقري لعملياتنا، مما يمكننا من تقديم منتجات وخدمات عالية الجودة لعملائنا.",
    "NEW_TITLE2": "حوّل منتجاتك إلى أرباح مع <span>{{ShoppyAI}}</span>",
    "HEADER7": "اختر قالبك",
    "TITLE7": "تم تصميم قوالبنا لتسهيل التنقل على أي جهاز لضمان تجربة تسوق رائعة.",
    "HEADER8": "أضف منتجاتك",
    "TITLE8": "أضف أو استورد منتجاتك، بما في ذلك الأوصاف والمواصفات والصور.",
    "HEADER9": "إعداد الشحن والضرائب",
    "TITLE9": "عرض أسعار الضرائب وخيارات الشحن الخاصة بالموقع في متجرك.",
    "HEADER10": "انشر متجرك",
    "TITLE10": "اذهب إلى الإنترنت بنقرة واحدة وشاهد التحديثات تنعكس على موقعك على الفور.",
    "HEADER11": "التكامل مع بوابات الدفع",
    "TITLE11": "قم بربط متجرك مع بوابات الدفع الشائعة أو دع العملاء يدفعون بشكل غير متصل.",
    "HEADER12": "أطلق علامتك التجارية الإلكترونية الخاصة!",
    "TITLE12": "طريقك إلى الربح يبدأ هنا!",
    "GET_FREE": "احصل على تجربة مجانية",
    "REVIEW_HEADER": "ماذا يقول رواد الأعمال الآخرون عن <span>{{ShoppyAI}}</span>",
    "REVIEW_NAME1": "لاندون لي",
    "REVIEW1": "قررت الدخول في عمل الدروبشيبينغ قبل ثلاثة أشهر فقط. لم يكن لدي خبرة ولم أعرف من أين أبدأ. بحثت عن برامج دروبشيبينغ ووجدت ShoppyAI. ماذا تتوقع؟ بعد ثلاثة أشهر، قد قمت بالفعل بتنفيذ أكثر من 50 طلبًا! ساعدني هذا الأداة في العثور على المنتجات الرائجة في الرعاية الصحية وتنظيم متجري عبر الإنترنت بشكل أكثر فعالية.",
    "REVIEW_NAME2": "غراسّيلا تايلر",
    "REVIEW2": "ساعدتني ShoppyAI في إعادة بدء عملي. فقدت مورد محلي لمجوهرات النساء وأردت العثور على طرق جديدة لتنظيم الأعمال. كنت خائفة من البدء في الدروبشيبينغ دون وجود سلع في مخزني؛ لم أفعل ذلك من قبل. ومع ذلك، جربت ذلك، وتوسعت في اختيار المجوهرات الخزفية الرائعة لعملائي وزيادة أرباحي!",
    "REVIEW_NAME3": "إيديسون برايس",
    "REVIEW3": "ShoppyAI ليست أول أداة دروبشيبينغ لي. جربت عدة أدوات للبحث عن المنتجات للبيع، وأنشطة إعادة التسعير، وإنشاء المتاجر عبر الإنترنت. لم أتوقع كل ما يمكن أن أجده في واحدة بسعر منتج معقول. الآن، أوصي بـ ShoppyAI لزملائي كأفضل منتج يستحق ثمنه.",
    "REVIEW_NAME4": "أبيل نيلسون",
    "REVIEW4": "في عصرنا الرقمي، من الرائع أن يكون هناك شيء يمكنه القيام بكل العمل من أجلك! ShoppyAI هي أفضل أداة للautomação! تمكنت من إدارة خمسة متاجر نشطة عبر الإنترنت حيث يتم تنفيذ جميع الطلبات تلقائيًا. أركز على حملات الترويج واستلام أرباحي فقط.",
    "FREQUENTLY_ASKED_QUESTIONS": "الأسئلة الشائعة؟",
    "FEATURES": "الميزات",
    "BENEFITS": "الفوائد",
    "INTEGRATIONS": "التكاملات",
    "SUPPLIERS": "الموردون",
    "FAQ": "الأسئلة الشائعة",
    "DASHBOARD": "لوحة التحكم",
    "SIGN_UP": "سجل الآن",
    "SIGN_IN": "تسجيل الدخول",
    "START_CHAT": "ابدأ محادثة",
    "FOOTER1": "في ShoppyAI، نحن لا نبيع،",
    "FOOTER2": "نحن نحقق النجاح!",
    "REGISTERED_ADDRESS": "عنوان المكتب المسجل",
    "ShoppyAI": "ShoppyAI",
    "ABOUT": "عن",
    "INVESTORS": "المستثمرون",
    "PARTNERS": "الشركاء",
    "AFFILIATES": "الشركاء التابعون",
    "LEGAL": "القانونية",
    "SERVICE_STATUS": "حالة الخدمة",
    "SUPPORT": "الدعم",
    "MERCHANT_SUPPORT": "دعم التجار",
    "HELP_CENTER": "مركز المساعدة",
    "HIRE_PARTNER": "توظيف شريك",
    "COMMUNITY": "المجتمع",
    "PRODUCTS": "المنتجات",
    "SHOP": "تسوق",
    "SHOP_PAY": "دفع التسوق",
    "ShoppyAI_PLUS": "ShoppyAI بلس",
    "LINKPOP": "Linkpop",
    "DEVELOPERS": "المطورون",
    "ShoppyAI_DEV": "ShoppyAI.dev",
    "API_DOCUMENT": "وثائق API",
    "DEV_DEGREE": "درجة المطور",
    "TERMS_OF_SERVICE": "شروط الخدمة",
    "PRIVACY_POLICY": "سياسة الخصوصية",
    "SITEMAP": "خريطة الموقع",
    "PRIVACY_CHOICES": "خيارات الخصوصية",
    "COPY_RIGHTS": "حقوق الطبع والنشر © {{year}} <a href='/'>{{title}}</a> جميع الحقوق محفوظة",

    "REGISTER": "تسجيل",
    "REGISTER_HEADER": "سجل مع <span>{{ShoppyAI}}</span>",
    "REGLOGIN_CONTENT": "قم بتسجيل الدخول الآن إلى ShoppyAI لإدارة حساب الدروبشيبينغ الخاص بك بشكل أفضل وزيادة أرباحك!",
    "FIRST_NAME": "الاسم الأول",
    "FIRST_NAME_PLACEHOLDER": "أدخل اسمك الأول",
    "FIRST_NAME_REQUIRED": "الاسم الأول مطلوب",
    "ALPHABETS_CHECK": "يسمح فقط بالحروف الأبجدية",
    "LAST_NAME": "اسم العائلة",
    "LAST_NAME_PLACEHOLDER": "أدخل اسم عائلتك",
    "LAST_NAME_REQUIRED": "اسم العائلة مطلوب",
    "EMAIL": "عنوان البريد الإلكتروني",
    "EMAIL_PLACEHOLDER": "أدخل عنوان بريدك الإلكتروني",
    "EMAIL_REQUIRED": "البريد الإلكتروني مطلوب",
    "EMAIL_INVALID": "عنوان البريد الإلكتروني غير صالح",
    "EMAIL_NOT_EXISTS": "البريد الإلكتروني غير موجود",
    "EMAIL_NOT_FOUND": "لا يمكننا العثور على مستخدم بهذا العنوان الإلكتروني.",
    "EMAIL_EXISTS": "البريد الإلكتروني موجود بالفعل",
    "EMAIL_NOTVERIFIED": "البريد الإلكتروني غير مُحقق",
    "MOBILE_NUMBER": "رقم الهاتف المحمول",
    "MOBILE_PLACEHOLDER": "أدخل رقم هاتفك المحمول",
    "MOBILE_NUMBER_REQUIRED": "رقم الهاتف المحمول مطلوب",
    "MOBILE_NUMBER_INVALID": "رقم الهاتف المحمول غير صالح",
    "MOBILE_NUMBER_EXISTS": "رقم الهاتف المحمول موجود بالفعل",
    "PASSWORD": "كلمة المرور",
    "PASSWORD_PLACEHOLDER": "أدخل كلمة المرور الخاصة بك",
    "PASSWORD_REQUIRED": "كلمة المرور مطلوبة",
    "PASSWORD_INCORRECT": "كلمة المرور غير صحيحة",
    "PASSWORD_MIN_MAX": "يجب أن تحتوي كلمة المرور على حرف كبير واحد على الأقل، حرف صغير واحد على الأقل، رقم واحد على الأقل، رمز خاص واحد على الأقل، ويجب أن تكون بين 8 و 18 حرفًا.",
    "PASSWORD_MIN": "يجب أن تحتوي كلمة المرور على 8 أحرف على الأقل",
    "PASSWORD_MAX": "يجب أن تحتوي كلمة المرور على أقل من 18 حرفًا",
    "PASSWORD_UPPER": "يجب أن تحتوي كلمة المرور على حرف كبير واحد",
    "PASSWORD_LOWER": "يجب أن تحتوي كلمة المرور على حرف صغير واحد",
    "PASSWORD_SPECIAL": "يجب أن تحتوي كلمة المرور على رمز خاص واحد",
    "PASSWORD_NUMERIC": "يجب أن تحتوي كلمة المرور على رقم واحد على الأقل",
    "YOUR_PASSWORD": "كلمة المرور الخاصة بك هي",
    "STRONG": "قوي",
    "MEDIUM": "متوسط",
    "WEAK": "ضعيف",
    "CONFIRM_PASSWORD": "تأكيد كلمة المرور",
    "CONFIRM_PASSWORD_PLACEHOLDER": "أعد إدخال كلمة المرور",
    "CONFIRM_PASSWORD_REQUIRED": "تأكيد كلمة المرور مطلوب",
    "CONFIRM_PASSWORD_MISMATCH": "كلمة المرور وتأكيد كلمة المرور لا تتطابقان",
    "INVALID_REFERRAL_CODE": "رمز الإحالة غير صالح",
    "ALREADY_HAVE_ACCOUNT": "هل لديك حساب بالفعل",
    "LOGIN_HERE": "قم بتسجيل الدخول هنا",
    "REGISTER_HERE": "سجل هنا",
    "LOGIN": "تسجيل الدخول",
    "LOGIN_HEADER": "تسجيل الدخول مع <span>{{ShoppyAI}}</span>",
    "REMEMBER_ME": "تذكرني",
    "FORGOT_PASSWORD": "نسيت كلمة المرور",
    "FORGOT_SUCCESS": "نجاح! لقد أرسلنا لك رابط إعادة تعيين كلمة المرور عبر البريد الإلكتروني!",
    "DONT_HAVE_ACCOUNT": "لا تملك حسابًا",
    "SUBMIT": "إرسال",
    "REGISTER_SUCCESS": "تم التسجيل بنجاح",
    "LOGIN_SUCCESS": "تم تسجيل الدخول بنجاح",
    "ACCOUNT_LOCKED": "تم قفل حسابك بواسطة المدير",
    "SOMETHING_WRONG": "حدث خطأ ما",
    "ERROR_ON_SERVER": "خطأ في الخادم",
    "ERROR_OCCURED": "حدث خطأ",
    "VERIFY_ACCOUNT": "تحقق من حسابك",
    "VERIFY_BOX": "تم تسجيل حسابك بنجاح ويحتاج إلى تحقق عبر البريد الإلكتروني. إذا لم تستلم البريد الإلكتروني،",
    "CLICK_HERE": "انقر هنا",
    "VERIFY_CONTENT1": "لقد أرسلنا بريدًا إلكترونيًا برابط التأكيد إلى عنوان بريدك الإلكتروني. لإكمال عملية التسجيل، يرجى النقر على رابط التأكيد.",
    "VERIFY_CONTENT2": "إذا لم تستلم أي بريد إلكتروني، يرجى التحقق من مجلد البريد العشوائي أو الانتظار لبضع دقائق لاستلام البريد. أيضًا، يرجى التأكد من أنك أدخلت عنوان بريد إلكتروني صالح أثناء التسجيل.",
    "ALREADY_VERIFIED": "لقد تم تفعيل بريدك الإلكتروني. يرجى تسجيل الدخول إلى حسابك!",
    "ACCOUNT_VERIFIED": "لقد تم تفعيل حسابك بالفعل. يرجى تسجيل الدخول إلى حسابك!",
    "USER_NOT_FOUND": "لم يتم العثور على المستخدم",
    "RESENT_SUCCESS": "تم الإرسال مرة أخرى بنجاح",
    "RESET_PASSWORD": "إعادة تعيين كلمة المرور",
    "CONFIRM": "تأكيد",
    "RESET_SUCCESS": "تمت إعادة تعيين كلمة المرور بنجاح",
    "RESET_LINK_EXPIRED": "رابط إعادة تعيين كلمة المرور الخاص بك قد انتهت صلاحيته",
    "RESENT_MAIL": "لقد أرسلنا لك رابط التفعيل مرة أخرى. يرجى التحقق من صندوق الوارد/مجلد البريد العشوائي الخاص بك!",
    "REQUIRED": "مطلوب",
    "MYSTORE": "متجري / إضافة متجر",
    "MARKETPLACE": "سوق",
    "VIP_MARKETPLACE": "سوق VIP",
    "ORDERS": "الطلبات",
    "ORDER": "طلب",
    "FINANCE": "المالية",
    "MY_INVENTORY": "مخزوني",
    "PACKAGE_SOLUTIONS": "حلول الحزم",
    "CUSTOMER_SUPPORT": "دعم العملاء",
    "REFER": "أحِل صديقًا",
    "BALANCE": "الرصيد",
    "DEPOSIT": "إيداع",
    "WITHDRAW": "سحب",
    "SUBSCRIPTION_PACKAGE": "حزمة الاشتراك",
    "PACKAGE": "حزمة",
    "WELCOME": "مرحبًا",
    "NOTIFICATION": "إشعار",
    "CLEARALL": "مسح الكل",
    "BANK_DETAILS": "تفاصيل البنك",
    "CHANGE_PASSWORD": "تغيير كلمة المرور",
    "LOGOUT": "تسجيل الخروج",
    "CHANGE_LANG": "تغيير اللغة",
    "AMOUNT": "المبلغ",
    "PROCEED_PAY": "المتابعة للدفع",
    "PROCEED_WITHDRAW": "المتابعة للسحب",
    "DEPOSIT_PLACEHOLDER": "أدخل مبلغ الإيداع الخاص بك",
    "WITHDRAW_PLACEHOLDER": "أدخل مبلغ السحب",
    "AMOUNT_REQUIRED": "يرجى إدخال المبلغ بالدولار الأمريكي",
    "INVALID_AMOUNT": "حقل المبلغ يجب أن يقبل الأرقام فقط",
    "AMOUNT_ERROR": "يجب أن يكون المبلغ أكبر من أو يساوي {{AMOUNT}} دولار أمريكي",
    "WITHDRAW_AMOUNT_REQUIRED": "مبلغ السحب مطلوب",
    "INVALID_WITHDRAW_AMOUNT": "يسمح بالأرقام فقط",
    "BALANCE_TOO_LOW": "رصيدك منخفض",
    "WITHDRAW_SUCCESS": "تم إرسال طلب السحب بنجاح",
    "INSUFFICIENT_AMOUNT": "المبلغ غير كافٍ",
    "CURRENT_PASSWORD": "كلمة المرور الحالية",
    "NEW_PASSWORD": "كلمة المرور الجديدة",
    "NEW_PASSWORD_REQUIRED": "كلمة المرور الجديدة مطلوبة",
    "CONFIRM_NEW_PASSWORD": "تأكيد كلمة المرور الجديدة",
    "NEW_CONFIRM_MISMATCH": "كلمة المرور الجديدة وتأكيد كلمة المرور لا تتطابقان",
    "PASSWORD_SAME": "لا يمكن أن تكون كلمة المرور الجديدة هي نفس كلمة المرور القديمة",
    "TRY_ANOTHER_PASSWORD": "يرجى تجربة كلمة مرور أخرى",
    "CHANGE_PASSWORD_SUCCESS": "تم تغيير كلمة المرور بنجاح",
    "NAME_BANK": "الاسم على البنك",
    "NAME_REQUIRED": "الاسم مطلوب",
    "INVALID_NAME": "اسم غير صالح",
    "NAME_BANK_PLACEHOLDER": "أدخل اسم صاحب الحساب",
    "IBAN": "IBAN",
    "IBAN_PLACEHOLDER": "أدخل IBAN",
    "IBAN_REQUIRED": "IBAN مطلوب",
    "INVALID_IBAN": "IBAN غير صالح",
    "SWIFT": "Swift bic",
    "SWIFT_PLACEHOLDER": "أدخل Swift bic",
    "SWIFT_REQUIRED": "Swift bic مطلوب",
    "INVALID_SWIFT": "Swift bic غير صالح",
    "BANK_NAME": "اسم البنك",
    "BANK_NAME_REQUIRED": "اسم البنك مطلوب",
    "INVALID_BANK_NAME": "اسم البنك غير صالح",
    "BANK_PLACEHOLDER": "أدخل اسم البنك",
    "BANK_DETAIL_SUCCESS": "تم رفع تفاصيل البنك بنجاح",
    "BANK_PENDING": "تم الرفع بنجاح، في انتظار التحقق",
    "BANK_REJECTED": "تم رفض طلبك من قبل المدير",
    "SUCCESS": "نجاح",
    "REJECTED": "مرفوض",
    "CREATE_REFERRAL": "إنشاء إحالة",
    "REFERRAL_SUCCESS": "تمت إضافة الإحالة بنجاح",
    "CREATE_SUPPORT_TICKET": "إنشاء تذكرة دعم",
    "CATEGORY": "الفئة",
    "SELECT_CATEGORY": "اختر فئة",
    "CATEGORY_REQUIRED": "الفئة مطلوبة",
    "MESSAGE": "رسالة",
    "MESSAGE_REQUIRED": "الرسالة مطلوبة",
    "MESSAGE_ERROR": "يرجى إدخال رسالتك",
    "UPLOAD_FILE": "مرفق (أضف ملفًا أو اسحب الملفات هنا)",
    "UPLOAD_SIZE_RESTRICT": "ملاحظة: الحد الأقصى 1 ميجابايت (فقط .jpeg، .png، .jpg، .pdf)",
    "INVALID_IMAGE": "تنسيق الصورة غير صالح",
    "SUPPORT_ERROR": "لم يتم تقديم تذكرة الدعم",
    "SUPPORT_SUCCESS": "تم تقديم تذكرة الدعم بنجاح",
    "TICKET_DETAILS": "تفاصيل التذكرة",
    "TICKET_ID": "معرف التذكرة",
    "SUB": "فرعي",
    "SEND": "إرسال",
    "OPEN": "مفتوح",
    "CLOSED": "مغلق",
    "ALL": "الكل",
    "ISSUE": "المشكلة",
    "STATUS": "الحالة",
    "ADMIN": "المدير",
    "YOU": "أنت",
    "FILE": "ملف",
    "NO_RECORD": "لا توجد سجلات",
    "CLOSE_TICKET": "راضٍ، اغلق هذه التذكرة",
    "CLOSE_SUCCESS": "تم إغلاق التذكرة بنجاح",
    "REPLY_SUBMIT": "تم تقديم الرد بنجاح",
    "MESSAGE_SENT_SUCCESS": "تم إرسال رسالتك بنجاح",
    "TICKET_ALREADY_CLOSED": "التذكرة مغلقة بالفعل",
    "DAILY_PROFIT": "الربح اليومي",
    "CURRENCY": "العملة",
    "TOTAL_ORDERS": "إجمالي الطلبات",
    "TOTAL_PROFIT": "إجمالي الربح",
    "MY_PRODUCTS": "منتجاتي",
    "NEW_PRODUCTS": "منتجات جديدة",
    "SALES_OVERVIEW": "نظرة عامة على المبيعات",
    "DAYS": "أيام",
    "WEEK": "أسبوع",
    "MONTH": "شهر",
    "OVERALL": "إجمالي",
    "OVERVIEW": "نظرة عامة",
    "TOTAL_SELL_COST": "إجمالي تكلفة الطلبات المباعة",
    "MAINTENANCE_FEE": "رسوم صيانة المتجر الشهرية",
    "ADVERTISEMENT": "إعلان",
    "SHIPPING": "الشحن",
    "STARTUP": "بدء التشغيل",
    "TOTAL_FEE": "الإجمالي",
    "WORLD_OVERVIEW": "نظرة عامة على تغطية المتجر عالميًا",
    "ORDER_LIST": "قائمة الطلبات",
    "VIEW_ORDERS": "عرض المزيد من الطلبات",
    "TOP_SELLING": "أفضل المنتجات مبيعًا",
    "ADD_PRODUCTS": "إضافة منتجات",
    "PRODUCT_NOT_FOUND": "لم نجد أي منتجات",
    "PROFIT": "الربح",
    "PRODUCT_COST": "تكلفة المنتج",
    "IMPORT_COST": "تكلفة الاستيراد",
    "ONE_TIME": "دفعة لمرة واحدة",
    "DEPOSIT_SUCCESS": "تم إيداع المبلغ بنجاح",
    "PRODUCT_NAME": "اسم المنتج",
    "ORDERID": "معرف الطلب",
    "STORE_NAME": "اسم المتجر",
    "DATE_TIME": "التاريخ والوقت",
    "TOTAL_SALE": "إجمالي المبيعات",
    "ALL_CATEGORY": "جميع الفئات",
    "PRODUCT_PRICE": "سعر المنتج",
    "IMPORT_PRICE": "سعر الاستيراد",
    "HIGH": "مرتفع",
    "LOW": "منخفض",
    "SORT_BY": "ترتيب حسب",
    "RECENTS": "الأحدث",
    "OLDEST": "الأقدم",
    "TYPE_SOME": "اكتب شيئًا",
    "GALLERY": "معرض",
    "LIST": "قائمة",
    "ADDED_CART": "تمت الإضافة إلى السلة",
    "IMPORT_CART": "استيراد إلى السلة",
    "SUBSCRIBE": "يرجى الاشتراك في VIP",
    "ACCOUNT_HISTORY": "تاريخ الحساب",
    "SEARCH": "بحث",
    "ORDERS_NOT_FOUND": "لم نجد أي طلبات",
    "REASON": "السبب",
    "TYPE": "النوع",
    "PAYMENT_ID": "معرف الدفع",
    "PENDING": "قيد الانتظار",
    "COMPLETED": "مكتمل",
    "ADMIN_DEPOSIT": "إيداع المدير",
    "USER_NOT_FINISHED": "إيداع المستخدم لم يكتمل",
    "SUCCEEDED": "نجح",
    "USER": "المستخدم",
    "USER_NAME": "اسم المستخدم",
    "USER_EMAIL": "البريد الإلكتروني للمستخدم",
    "BEFORE_BALANCE": "قبل الرصيد",
    "AFTER_BALANCE": "بعد الرصيد",
    "BILLED_MONTH": "مبلغ شهري",
    "SUBSCRIBED": "مشترك",
    "BUY_NOW": "اشترِ الآن",
    "BUSINESS_DAYS": "أيام العمل للشحن",
    "AIR_HUB": "مراكز الطيران",
    "FOR": "لـ",
    "INCLUDES": "يتضمن",
    "AUTHORIZED": "مركز الشحن المعتمد® (FASC) والشركاء",
    "ARE_YOU": "هل أنت متأكد من الشراء",
    "PACKAGE_WITH": "حزمة مع",
    "CANCEL": "إلغاء",
    "INSUFFICIENT_USD": "رصيد الدولار الأمريكي لديك منخفض",
    "SUBSCRIBED_SUCCESS": "تم الاشتراك بنجاح",
    "NO_PACKAGE": "لا توجد حزمة",
    "ADVERTISE": "الإعلان لـ",
    "CLIENT_WORLD": "العملاء في جميع أنحاء العالم",
    "AIR_EXPRESS_HUBS": "مراكز الشحن السريع الجوي",
    "AVERAGE_DAILY_PACKAGE": "متوسط حجم الحزم اليومية",
    "AIRPORTS": "المطارات المخدومة",
    "SELL_COST": "تكلفة البيع",
    "SELECT_STORE": "اختر المتجر",
    "VIEW_PRODUCTS": "عرض المنتج",
    "STORE_NAME": "اسم المتجر",
    "EDIT_SUCCESS": "تم التعديل بنجاح",
    "EDIT_SELL_PRICE": "تعديل سعر البيع",
    "ID": "معرف",
    "CREATED": "تم الإنشاء",
    "SELLING_PRICE": "سعر البيع",
    "SELLING_PRICE_PLACEHOLDER": "أدخل مبلغ البيع",
    "WAITING": "في انتظار",
    "SELLING_PRICE_REQUIRED": "يجب ألا يكون سعر البيع فارغًا",
    "SELLILNG_PRICE_MAX": "يجب أن تكون تكلفة البيع أكبر من تكلفة المنتج",
    "ARE_YOU_DELETE": "هل أنت متأكد من الحذف",
    "DELETE_SUCCESS": "تم حذف المنتج بنجاح",
    "DELETE_NOTE": "ملاحظة: لم نعد إليك تكلفة الاستيراد لحذف هذا المنتج",
    "PRODUCT_DETAILS": "تفاصيل المنتج",
    "PRODUCT_ID": "معرف المنتج",
    "REVIEWS": "المراجعات",
    "SHIPS_FROM": "يشحن من",
    "22BUSINESS_DAYS": "22 يوم عمل إلى",
    "US": "الولايات المتحدة",
    "PRODUCT_INFO": "معلومات المنتج",
    "SPECIFICATIONS": "المواصفات",
    "POLICY": "السياسة",
    "ITEM_COST": "تكلفة العنصر",
    "NUMBER_ORDERS": "عدد الطلبات",
    "ADDED_DRAFT": "أضيف إلى المسودة",
    "IMPORT_PRODUCT": "استيراد المنتج",
    "PRODUCT_NOT_FOUND": "لم يتم العثور على المنتج",
    "ALREADY_DRAFT": "عذرًا، العنصر موجود بالفعل في المسودة",
    "QUANTITY": "الكمية",
    "ACTUAL_PRICE": "السعر الفعلي",
    "USER_PROFIT": "ربح المستخدم",
    "RETURN": "عودة",
    "SEARCH_PRODUCT": "ابحث عن منتج أو متجر",
    "SELECT_DATE": "اختر التاريخ",
    "STORE": "المتجر",
    "SEARCH_STORE": "ابحث عن اسم المتجر",
    "SETTINGS": "الإعدادات",
    "ADD_STORE": "إضافة متجر",
    "EDIT": "تعديل",
    "SEARCH_ANYTHING": "ابحث عن أي شيء",
    "STORE_LOCATION": "موقع المتجر",
    "SEARCH_COUNTRY": "ابحث عن دولة...",
    "STORE_LOGO": "شعار المتجر",
    "STORE_LOGO_RESTRICT": "الصيغة المقبولة: png، jpeg، jpg",
    "UPDATE_SUCCESS": "تم التحديث بنجاح",
    "STORE_NAME_REQUIRED": "اسم المتجر مطلوب",
    "COUNTRY_REQUIRED": "الدولة مطلوبة",
    "SELECT_DEFAULT": "يرجى اختيار المتجر الافتراضي",
    "FILE_SIZE": "حجم الملف يتجاوز 30 ميغابايت",
    "SELECT_COUNTRY": "اختر الدولة",
    "STORE_NAME_PLACEHOLDER": "أدخل اسم المتجر",
    "ACCEPTABLE_SIZE": "الحجم المقبول: 30 ميغابايت، الصيغة: png، jpeg، jpg",
    "SELECT_STORE_TYPE": "اختر نوع المتجر",
    "CREATE_STORE": "إنشاء متجر",
    "STORE_ALREADY_EXISTS": "المتجر موجود بالفعل",
    "STORE_SUCCESS": "تم إنشاء المتجر بنجاح",
    "NO_DATA": "لا توجد بيانات",
    "DRAFT_PRODUCT": "منتج مسودة",
    "BACK": "عودة",
    "ALL_PRODUCT": "جميع المنتجات",
    "FAILED": "فشل",
    "SUCCESSFUL": "ناجح",
    "ACTIONS": "الإجراءات - استيراد سريع",
    "IMPORT": "استيراد",
    "FOURTH_TAB": "محتوى التبويب الرابع",
    "ARE_YOU_REMOVE": "هل أنت متأكد من الإزالة",
    "SELECT_YOUR_STORE": "اختر متجرك",
    "SELLING_PRICE_ERROR": "لا يوجد منتج بسعر بيع",
    "SELECT_STORE_ERROR": "يرجى اختيار متجرك",
    "PRODUCT": "المنتج",
    "AVERAGE_MARKPRICE": "متوسط سعر السوق",
    "ACTION": "الإجراء",
    "SELL_PRICE": "سعر البيع",
    "INSUFFICIENT_FUNDS": "أموال غير كافية",
    "REMOVE_SUCCESS": "تمت الإزالة بنجاح",
    "STORE_REQUIRED": "المتجر مطلوب",
    "PROFIT_PER_ORDER": "الربح لكل طلب",
    "IMPORT_STORE": "استيراد المتجر",
    "PRODUCT_ALREADY_SELECTED": "المنتج موجود بالفعل في المتجر المحدد",
    "IMPORT_SUCCESS": "تم استيراد المنتج بنجاح",
    "ALREADY_DEPOSITED": "تم الإيداع بالفعل",
    "CONFIRM_MAIL_SUCCESS": "تم التحقق من بريدك الإلكتروني، يمكنك الآن تسجيل الدخول",
    "IS_ACTIVE": "بريدك الإلكتروني تم التحقق منه بالفعل",
    "UNAUTHORIZED": "غير مصرح",
    "SHIPPING_TIME": "وقت الشحن",
    "BUSSINESS_DAYS": "أيام العمل",
    "PRODUCT_SUCCESS": "{{SUCCESS}} تم استيراد المنتج بنجاح {{FAILER}} فشل استيراد المنتج",




    "WALLET": "محفظة",
    "BANK_DETAILS": "تفاصيل البنك",
    "DEPOSIT_TERMS": "شروط الإيداع",
    "MINIMUM_DEPOSIT_AMOUNT": "الحد الأدنى لمبلغ الإيداع: ",
    "INVALID_IMAGE": "صورة غير صالحة",
    "MAINTENANCE_ERROR": "صيانة جارية، يرجى المحاولة لاحقًا!",
    "DEPOSIT_DESCRIPTION": "لن يتم قبول الودائع أقل من المبلغ الأدنى ولا يسمح بالاسترداد",
    "DEPOSIT_NOTES1": "أرسل فقط {{CURRENCY_SYMBOL}} إلى عنوان المحفظة هذا.",
    "DEPOSIT_NOTES2": "إرسال العملات أو الرموز غير {{CURRENCY_SYMBOL}} إلى هذه المحفظة قد يؤدي إلى فقدان إجمالي الوديعة",
    "DEPOSIT_NOTES3": "يرجى رفع لقطة شاشة أو هاش للمعاملة الخاصة بك لكي نتمكن من الموافقة عليها",
    "DEPOSIT_CARD": "إيداع بواسطة البطاقة",
    "DEPOSIT_ADDRESS": "عنوان الإيداع",
    "MEMO_ADDRESS": "عنوان المذكرة",
    "DEPOSIT_IMAGE_RESTRICTION": "أقصى حجم لرفع الملف 2 ميجابايت (Jpg، Jpeg، Png، Pdf فقط).",
    "DEPOSIT_AMOUNT_ERROR": "يرجى إدخال مبلغ الإيداع أكبر من أو يساوي {{AMOUNT}}",
    "DEPOSIT_SUCCESS": "تم إرسال طلب الإيداع بنجاح",
    "ASSET_ERROR": "حقل الأصول للمستخدم مطلوب",
    "COPIED": "تم النسخ!",
    "DOCUMENT_UPLOAD": "رفع مستند (اختياري)",
    "UPLOAD": "رفع",
    "WITHDRAW_TERMS": "شروط السحب",
    "MINIMUM_WITHDRAW_AMOUNT": "الحد الأدنى لمبلغ السحب هو: ",
    "TERMS1": "الحد الأقصى للسحب:",
    "TERMS2": "رسوم السحب:",
    "RECEIVER_ADDRESS": "عنوان المستلم",
    "RECEIVER_ADDRESS_PLACEHOLDER": "يرجى إدخال عنوان السحب هنا",
    "RECEIVER_ADDRESS_REQUIRED": "حقل عنوان المستلم مطلوب",
    "WITHDRAW_AMOUNT": "مبلغ السحب",
    "WITHDRAW_AMOUNT_ERROR": "يرجى إدخال مبلغ السحب أكبر من أو يساوي {{AMOUNT}}",
    "MINIMUM_WITHDRAW_ERROR": "يجب أن يكون الحد الأدنى لمبلغ السحب أكبر من أو يساوي {{AMOUNT}}",
    "WITHDRAW_MAXAMOUNT_ERROR": "يجب أن يكون مبلغ السحب أقل من أو يساوي {{AMOUNT}}",
    "WITHDRAW_SUCCESS": "تم إرسال طلب السحب بنجاح",
    "INSUFFICIENT_WITHDRAW": "مبلغ السحب منخفض جدًا",
    "WITHDRAW_OTP": "تم إرسال رمز التحقق إلى عنوان بريدك الإلكتروني بنجاح. يرجى التحقق من صندوق الوارد الخاص بك واستخدام الرمز المقدم للمضي قدمًا",
    "HOLDER_NAME": "اسم صاحب الحساب",
    "BANK_CODE": "رمز البنك",
    "BANKCODE_REQUIRED": "رمز البنك مطلوب",
    "INVALID_CODE": "رمز غير صالح",
    "ACCOUNTNO": "رقم الحساب",
    "ACCOUNTNO_REQUIRED": "رقم الحساب مطلوب",
    "INVALID_ACCOUNTNO": "رقم غير صالح",
    "COUNTRY": "البلد",
    "TOTAL_BALANCE": "إجمالي الرصيد",
    "BALANCE": "الرصيد",
    "EXCHANGE": "تبادل",
    "HIDE_ZERO_BALANCES": "إخفاء الأرصدة الصفرية",
    "FROM_CURRENCY": "من العملة",
    "TO_CURRENCY": "إلى العملة",
    "TO_CURRENCY_REQUIRED": "حقل العملة المستهدفة مطلوب",
    "SELECT_CURRENCY": "اختر العملة",
    "INVALID_USER": "مستخدم غير صالح",
    "INSUFFICIENT_BALANCE": "رصيد غير كاف",
    "TRANSFER_SUCCESS": "تم النقل بنجاح",
    "AMOUNT_CAN'T_ZERO": "يجب أن يكون المبلغ أكبر من صفر",
    "AVAILABLE_BALANCE": "الرصيد المتاح",
    "MAX": "الحد الأقصى",
    "TOO_LARGE": "كبير جدًا",
    "ASSET": "أصل",
    "AMOUNT_PLACEHOLDER": "أدخل المبلغ",
    "OTP": "رمز التحقق",
    "OTP_PLACEHOLDER": "أدخل رمز التحقق",
    "RESENT_OTP": "إعادة إرسال رمز التحقق",
    "OTP_VALIDUPTO": "صالح حتى",
    "OTP_REQUIRED": "حقل رمز التحقق مطلوب",
    "OTPSTATUS_REQUIRED": "حالة رمز التحقق مطلوبة",
    "INVALID_OTPSTATUS": "حالة رمز التحقق غير صالحة",
    "OTP_SENT_SUCCESS": "تم إرسال رمز التحقق إلى بريدك الإلكتروني، يرجى إدخال رمز التحقق",
    "EXPIRED_OTP": "رمز التحقق منتهي الصلاحية",
    "INVALID_OTP": "رمز تحقق غير صالح",
    "SEC": "ثانية",
    "STORE_MARKETPLACE": "سوق المتجر",
    "STORE_PRICE": "سعر المتجر",
    "INVALID_CURRENCY": "عملة غير صالحة",
    "VALID_AMOUNT": "يرجى إدخال مبلغ صحيح",
    "INVALID_ASSET": "أصل غير صالح",
    "SITE_URL": "رابط الموقع",
    "PAYMENTYPE": "نوع الدفع",
    "CURRENCYSYMBOL": "رمز العملة",
    "COIN_DEPOSIT": "إيداع العملات",
    "COIN_WITHDRAW": "سحب العملات",
    "COIN_TRANSFER": "تحويل العملات",
    "FIAT_DEPOSIT": "إيداع نقدي",
    "FIAT_WITHDRAW": "سحب نقدي",
    "NEW_USER": "مستخدم جديد",
    "BUYING_STORE": "شراء المتجر",
    "BUYING_PRODUCT": "شراء المنتجات",
    "USER_DEPOSIT": "إيداع المستخدم",
    "USER_WITHDRAW": "سحب المستخدم قيد الانتظار",
    "USER_WITHDRAW_VERIFY": "سحب المستخدم تم التحقق منه",
    "USER_WITHDRAW_REJECT": "تم رفض سحب المستخدم",
    "ADMIN_WITHDRAW": "سحب المسؤول",
    "ADMIN_DEP": "إيداع المسؤول",
    "PROFITS": "الأرباح",
    "PACKAGE_SUBSCRIBE": "اشتراك في الحزمة",
    "STORE_RENEWAL": "تجديد المتجر",
    "RETURN_PRODUCT": "إرجاع المنتج",
    "COIN_TRANSFERS": "تحويل العملات",
    "ROWS_PER_PAGE": "الصفوف لكل صفحة",




    "ABOUT_US": "معلومات عنا",
    "ABOUT_LIST1": "تأسست ShoppyAI",
    "ABOUT_LIST2": "المستخدمون النشطون شهريًا",
    "ABOUT_LIST3": "الدول التي ينتمي إليها المستخدمون",
    "ABOUT_LIST4": "الموظفون",
    "ABOUNT_HEAD1": "منصتك الشاملة لأبحاث المنتجات والتنفيذ",
    "ABOUT_PARA1": "البحث الجيد عن المنتجات هو مفتاح النجاح، لكن الواقع في عملية البحث، والتعامل مع الوكلاء، والشحن الدولي، والجمارك هو أكثر تعقيدًا مما يتوقعه معظم الناس. رؤية ShoppyAI هي إزالة هذا التعقيد. ما تراه هو ما يمكنك الحصول عليه هنا في لوحة التحكم الخاصة بك ببضع نقرات فقط. لا خدمات أتمتة خارجية، ولا التعامل مع الموردين.",
    "ABOUNT_HEAD2": "تجربة المعرفة بدلاً من التخمين",
    "ABOUT_PARA2": "تعمل فريق أبحاث ShoppyAI مع بائعي المتاجر المستقلة، وأمازون، وإيباي من جميع الأحجام لاختبار الافتراضات ومساعدة محللي ShoppyAI في التحقق من بياناتهم. بفضل شبكة من الشركاء، ليست منهجية التنبؤ الخاصة بـ ShoppyAI ورقية فحسب، بل تم اختبارها بشكل كبير في الميدان. إنها أبحاث دقيقة، وليست أرقام وهمية تُنتجها معظم ما يُطلق عليهم \"متعقبو المبيعات\".",
    "ABOUNT_HEAD3": "الشفافية",
    "ABOUT_PARA3": "نحن نعرف مدى إحباط الشعور بالارتباك مع الكثير من المواقع المجهولة والأرقام الخيالية. نحن نحل ذلك. ShoppyAI هي شركة تقع في قلب لندن، ويقف مؤسسوها خلف منهجية البحث والخدمات الخاصة بهم بأسمائهم.",
    "ABOUNT_HEAD4": "الاحترافية",
    "ABOUT_PARA4": "نفهم احتياجاتك الأساسية لتتمكن من العمل كعمل تجاري: فواتير صحيحة، وشهادات منتجات ضرورية، ونصائح كفؤة. يتكون فريق الدعم من ناطقين باللغة الإنجليزية الأصليين الذين يمكنهم التعامل مع أصعب الأسئلة والبيروقراطية.",
    "ABOUNT_HEAD5": "روح ريادة الأعمال",
    "ABOUT_PARA5": "نريدك أن تكون ناجحًا، خاصة إذا كنت مبتدئًا. لهذا السبب جعلنا لوحة تحكم ShoppyAI سهلة الاستخدام قدر الإمكان، بسيطة جدًا، مع العديد من الميزات للتوسع والنمو التي نتمنى لو كنا نملكها عند بناء متاجرنا الأولى.",
    "ABOUNT_HEAD6": "التحسين",
    "ABOUT_PARA6": "لوحة تحكم ShoppyAI هي مجرد البداية. نحن نحسن، نضيف ميزات، نطلب التعليقات، ونطبق التعليقات. نعتقد أنه عندما تتحسن الجودة، يأتي النمو بشكل طبيعي. يرجى إعطائنا أكبر قدر ممكن من التعليقات. كل جزء يُحسب!",
    "ABOUNT_HEAD7": "التجارة الإلكترونية للجميع",
    "ABOUT_PARA7": "نريد عددًا أقل من اللاعبين الكبار، مثل أمازون وكوستكو، وعددًا أكبر من رواد الأعمال. تمكّن ShoppyAI البائعين المستقلين، مما يساعدهم في تشكيل سوق متنوعة للجميع. دعونا نتعلم من بعضنا البعض، ننمو معًا، ونساعد الآخرين على النجاح.",
    "ABOUNT_HEAD8": "الحب",
    "ABOUT_PARA8": "يبتكر أعضاء فريق ShoppyAI ميزات يحبونها. لا أحد ينفذ أي شيء لن يستخدمه بنفسه. نحن نجمع الناس الذين لديهم شغف عميق بالتجارة الإلكترونية. يتم مشاركة هذا الشغف مع كل مشترك عبر لوحة التحكم.",
    "AFFILIATE_PROGRAM": "برنامج الشراكة",
    "AFFILIATE_QUES": "من يمكنه الترويج لـ ShoppyAI",
    "AFFILIATE_HEAD1": "المبدعون المحتوون",
    "AFFILIATE_PARA1": "استخدم محتوى ShoppyAI الجاهز للترويج إذا كنت مبدع محتوى على فيسبوك، إنستغرام، أو تيك توك.",
    "AFFILIATE_HEAD2": "المؤثرون على وسائل التواصل الاجتماعي",
    "AFFILIATE_PARA2": "اكسب من خلال الترويج لـ ShoppyAI على وسائل التواصل الاجتماعي وإظهار حبك له كمؤثر.",
    "AFFILIATE_HEAD3": "يوتيوبर्स",
    "AFFILIATE_PARA3": "قدّم رؤى حول ShoppyAI من خلال فيديو على يوتيوب وضمّن رابط الشراكة الخاص بك في الوصف.",
    "AFFILIATE_HEAD4": "المعلمين",
    "AFFILIATE_PARA4": "حوّل محتواك إلى مصدر دخل من خلال تعليم الآخرين كيفية استخدام ShoppyAI بشكل فعال.",
    "AFFILIATE_HEAD5": "رواد الأعمال",
    "AFFILIATE_PARA5": "عزز من إمكانية تحقيق الدخل لمحتواك من خلال إرشاد الآخرين في الاستخدام الفعّال لـ ShoppyAI.",
    "AFFILIATE_HEAD6": "المدونون والكتّاب",
    "AFFILIATE_PARA6": "زد من ربحية منشورات مدونتك من خلال دمج إعلانات ShoppyAI ورابط الشراكة الخاص بك.",
    "UNLOCK_POTENTIAL": "افتح إمكانياتك",
    "COMMUNITY_PARA1": "انضم إلى مجموعة من عشاق التجارة الإلكترونية ذوي التفكير المماثل. شارك التجارب، اطرح الأسئلة، واحصل على نصائح من أشخاص يفهمون رحلتك.",
    "COMMUNITY_HEAD2": "اختيارات المنتجات المجانية",
    "COMMUNITY_PARA2": "احصل على وصول حصري إلى منتجات مختارة بعناية. هذه الاختيارات المجانية ستساعدك على اكتشاف عناصر جديدة لديها الإمكانية لتصبح من الأكثر مبيعًا في متجرك.",
    "COMMUNITY_HEAD3": "رؤى قيمة",
    "COMMUNITY_PARA3": "تعلم من الحكمة الجماعية للمجتمع. احصل على رؤى حول ما يعمل في التجارة الإلكترونية، مما يساعدك على اتخاذ قرارات تجارية أذكى.",
    "COMMUNITY_HEAD4": "فرص الشبكات",
    "COMMUNITY_PARA4": "تواصل مع شركاء محتملين أو مرشدين أو حتى مستثمرين. يمكن أن تفتح الشبكات آفاقًا جديدة لنمو عملك.",
    "COMMUNITY_HEAD5": "الأخبار",
    "COMMUNITY_PARA5": "ابق على اطلاع باتجاهات التجارة الإلكترونية. كونك جزءًا من هذا المجتمع يعني أنك ستسمع عن أحدث الأخبار والاستراتيجيات أولاً.",
    "COMMUNITY_HEAD6": "الدافع",
    "COMMUNITY_PARA6": "يمكن أن يوفر الانضمام إلى مجتمع داعم التشجيع اللازم للاستمرار في مواجهة تحديات إدارة عمل التجارة الإلكترونية.",
    "COMMUNITY_HEAD7": "لا تسقط مثلما كان في 2017",
    "COMMUNITY_PARA7": "تخيل أن شخصًا يسألك من هو المورد الخاص بك، ثم عليك أن تخبرهم أنه وكيل خاص، أو علي إكسبريس، أو أداة أتمتة تشتري ببساطة من علي إكسبريس من أجلك. إنه عام 2024. سيشكرونك عملاؤك، وإيراداتك، وأرباحك.",
    "TESTIMONIALS": "الشهادات",
    "WHAT_OTHERS": "ماذا يقول رواد الأعمال الآخرون عن ShoppyAI",
    "SAYS_HEAD1": "تيمو هيرتسوج",
    "SAYS_PARA1": "«بعد عامين في التجارة الإلكترونية وقليل من النجاح، أدركت أن أدوات تتبع المتاجر الدقيقة أو أدوات التجسس ببساطة لا توجد. النجاح في الدروب شيبينغ يتطلب بحثًا مناسبًا وموردًا موثوقًا للقيام بالعمل. كانت ShoppyAI هي نقطة التحول التي كنت بحاجة إليها. بحث حقيقي وشفاف بمستوى جامعي مصحوبًا بخدمات لوجستية وأتمتة خاصة. جعلني أنتقل من مجتهد إلى رائد أعمال في أقل من 3 أشهر.»",
    "SAYS_HEAD2": "ماريو جوردانو",
    "SAYS_PARA2": "«ShoppyAI صديقة جدًا للمبتدئين. للمبتدئين في الدروب شيبينغ، هذه هي بالتأكيد أفضل حل شامل. يتم إرسال المنتجات في عبوة محايدة، لذا لا يرى العميل النهائي أنها دروب شيبينغ. بالنسبة للمحترفين مثلي، لا يزال يفتقر إلى خيار العلامة التجارية. وفقًا للدعم، هم يعملون على ذلك. ومع ذلك، لا يزال الأفضل في البحث وتحقيق المنتجات التي لا تتطلب علامة تجارية.»",
    "SAYS_HEAD3": "ليزا-ماري ميسكيندال",
    "SAYS_PARA3": "«رائع للتعامل مع متاجر متعددة في الوقت نفسه واختبار المنتجات بسرعة. لا يوجد شيء مشابه لذلك. للدروب شيبينغ، هناك، ولكن للبقاء رشيقًا وليس للإفراط في الاستثمار، لا يوجد.»",
    "SAYS_HEAD4": "فريد سعدلو",
    "SAYS_PARA4": "«شحن موثوق عالميًا، أتمتة كاملة، بحث شفاف. أداة اتجاهات المنتجات رائعة بشكل خاص. أنا دائمًا مستعد للتفاعل مع مطالب الغد، وتحليل الموسم يساعدني على معرفة متى أبيع ماذا.»",
    "SAYS_HEAD5": "أغنيزا فيليو",
    "SAYS_PARA5": "«عندما دخلت عالم التجارة الإلكترونية منذ عدة سنوات، جعلتني تعقيدات الجمارك والاستيراد ومحاسبة الضرائب أفقد الاهتمام بسرعة. كنت دائمًا جيدًا في التسويق لكن سيئًا في العمليات، خاصة الأمور القانونية. قامت ShoppyAI بحل هذه القضايا لي، والآن أدير وأنمي متجري!»",
    "SAYS_HEAD6": "دومينيك غالاغر",
    "SAYS_PARA6": "«تتمتع ShoppyAI بأفضل دعم على الإطلاق! هم يهتمون، يطرحون أسئلة، يقدمون النصائح، ويريدون لك النجاح. دائمًا ما أدهش بجودة الدعم.»",
    "SAYS_HEAD7": "مارك مالين",
    "SAYS_PARA7": "«ما بدأ كعمل جانبي تحول إلى شركة تضم الآن ثمانية موظفين. كانت ShoppyAI معي طوال الطريق وما زالت أداة البحث وتحقيق المنتجات المفضلة لدي. أختبر موردين آخرين، لكن ذلك يتطلب الكثير من الإدارة الدقيقة. مع ShoppyAI أعلم أن كل شيء سيسير بسلاسة.»",
    "SAYS_HEAD8": "ديما فوروونوف",
    "SAYS_PARA8": "«لقد قمت بتوصيل متاجري. كل شيء يعمل. معظم الأدوات الشاملة ليست سوى أدوات أتمتة تربطك بوسطاء صينيين مشبوهين أو تشتري لك من علي إكسبريس. أحب أن ShoppyAI هو مورد فعلي. فواتير ضريبة القيمة المضافة، شهادات CE، ونصائح من ناطقين باللغة الإنجليزية لديهم خبرة واضحة في التجارة الإلكترونية. لم يعد هناك حاجة للتوسل إلى الوكلاء أو بائعي علي إكسبريس الذين لا يعرفون ما هي الفاتورة.»"

}