import * as XLSX from "xlsx"
import * as FileSaver from "file-saver";
import jsPDF from "jspdf";
import "jspdf-autotable";
//csv function
import moment from "moment";

function convertArrayOfObjectsToCSV(array) {
    let result

    const columnDelimiter = ','
    const lineDelimiter = '\n'
    const keys = Object.keys(array[0])

    result = ''
    result += keys.join(columnDelimiter)
    result += lineDelimiter

    array.forEach((item) => {
        let ctr = 0
        keys.forEach((key) => {
            if (ctr > 0) result += columnDelimiter

            result += item[key]

            ctr++
        })
        result += lineDelimiter
    })

    return result
}

export function downloadCSV(array, filename) {
    const link = document.createElement('a')
    let csv = convertArrayOfObjectsToCSV(array)
    if (csv == null) return

    if (!csv.match(/^data:text\/csv/i)) {
        csv = `data:text/csv;charset=utf-8,${csv}`
    }

    link.setAttribute('href', encodeURI(csv))
    link.setAttribute('download', filename)
    link.click()
}

//xlsx function

function getSheetData(data1) {
    let newArray = [];
    let data = Object.values(data1);
    Object.keys(data).forEach((key) => {

        var headers = Object.keys(data[key]);

        var obj = {};
        headers.forEach((header) => {
            obj[header] = data[key][header];
        })
        newArray.push(obj)
    })
    return newArray;
}

const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const fileExtension = ".xlsx";

export const downloadXls = (xlsxData, fileName) => {
    let data1 = getSheetData(xlsxData)

    const ws = XLSX.utils.json_to_sheet(data1);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);

}

//pdf function

export const downloadpdf = (pdfData, fileName) => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "landscape"; // portrait or landscape
    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);
    doc.setFontSize(8);
    const title = fileName;

    const headers = [["Date", "Time", "order ID", "Product Name", 'Store Name', 'Product Cost(USD)', 'Import Cost(USD)', 'Sell Cost(USD)', 'Profit(USD)']];

    const data1 = pdfData.map(elt => [moment(elt.createdAt).format('L'), moment(elt.createdAt).format('LTS'), elt.orderID, elt.ProductName, elt.StoreName, elt.product_cost, elt.import_cost, elt.sell_cost, elt.profit]);

    let content = {
        startY: 100,
        head: headers,
        body: data1,
        theme: 'grid',
        styles: {
            fontSize: 5,
            cellWidth: 'wrap'
        },
        columnStyles: {
            1: { columnWidth: 'auto' }
        }
    };
    var pageSize = doc.internal.pageSize;
    var pageHeight = pageSize.height
        ? pageSize.height
        : pageSize.getHeight();
    var str = "Copyright © ShoppyAI, All rights Reserved";

    doc.text(str, marginLeft, pageHeight - 40);
    doc.text(title, marginLeft, 68);
    doc.autoTable(content);
    doc.save(fileName)
}

